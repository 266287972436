<template>
  <div class="detalhar-lote-botoes">
    <a
      title="Cadastrar nova baixa"
      class="btn btn-success mr-3"
      :class="batch == null || batchDetail.date_departure !== null ? 'btn-baixa-disabled' : ''"
      href="#"
      data-toggle="modal"
      data-target="#baixaModal"
      ><img style="height: 24px" src="../assets/ico-lapide.svg" /> Cadastrar baixa</a
    >
    <a
      title="Cadastrar peso manual"
      class="btn btn-success mr-3"
      href="#"
      data-toggle="modal"
      data-target="#registerWeightModal"
      ><img  style="height: 20px" src="../assets/ico-edit.svg" /> Cadastrar peso</a
    >
  </div>
</template>

<script>
export default {
  name: "RegisterButtons",
  props:['batchDetail', 'batch'],
};
</script>

<style scoped>
  .btn-baixa-disabled{
    pointer-events: none;
    opacity: 0.3;
  }
</style>