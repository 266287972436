import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../views/LoginPage.vue'
import RecoverPasswordPage from '../views/RecoverPasswordPage.vue'
import HomePage from '../views/HomePage.vue'
import BatchDetailsPage from '../views/BatchDetailsPage.vue'
import BatchesHistoryPage from '../views/BatchesHistoryPage.vue'
import ListUsersPage from '../views/ListUsersPage.vue'
import SmartCamPage from '../components/SmartCamPage.vue'
import SmartMic from '../components/SmartMicV2.vue'
import EmbeddedBI from '../components/EmbeddedBI.vue'
import SmartFeedPage from '../components/SmartFeedPage.vue'
import BatchTab from '../components/BatchTab.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/recover-password',
    name: 'Recover',
    component: RecoverPasswordPage
  },
  {
    path: '/home-page',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/batch-details',
    component: BatchDetailsPage,
    props: true,
    children: [
      {
        path: 'batch-tab',
        name: 'BatchTab',
        component: BatchTab,
        props: true,
      },
      {
        path: 'smart-cam',
        name: 'SmartCamPage',
        component: SmartCamPage,
        props: true,
      },
      {
        path: 'smart-mic',
        name: 'SmartMic',
        component: SmartMic,
        props: true,
      },
      {
        path: 'smart-feed',
        name: 'SmartFeedPage',
        component: SmartFeedPage,
        props: true,
      },
      {
        path: 'embedded-bi',
        name: 'EmbeddedBI',
        component: EmbeddedBI,
        props: true,
      }
    ]
  },
  {
    path: '/batches-history',
    name: 'BatchesHistory',
    component: BatchesHistoryPage,
    props: true
  },
  {
    path: '/list-users',
    name: 'ListUsers',
    component: ListUsersPage
  }
]

const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes
})

export default router
