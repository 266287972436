<template>
  <div>
    <a class="card-button" @click="changeBarnTarget(barn, batch, sensors, '/batch-details/smart-cam')">
      <div title="Dado mais recente da SmartCam" :class="smartCamColor">
        <div
          class="progress-bar"
          role="progressbar"
          style="width: 100%; padding: 0; margin: 0 2px; border-radius: 4px"
        > 
          <AnimadePlaceHolder v-if="dataCam.length == 0" height="40px" width="100%" borderRadius="4px"/>
          <div class="container-information-td" v-else>
            <img class="imgIcon" src="../assets/kg-white.svg" />
            <p class="product-information">
                {{smartCamText}}
            </p>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { URL } from "../store/index"
import AnimadePlaceHolder from './AnimadePlaceHolder.vue'
export default {
  name: "SensorsCam",
  components: {
    AnimadePlaceHolder
  },
  data() {
    return {
        loading: true
    }
  },
  props: ["sensors", "indexBarn", 'barn', 'batch'],
  methods: {
    async getDataCam() {
      if (this.sensors.length > 0) {
        for (const element of this.sensors) {
          try {
            this.$store.commit('incrementActiveRequests');
            let url_to_fetch = `${URL}sensors/report/data/home/smartcam/smartcam_pk=${element.pk}`;
            const response = await fetch(url_to_fetch, {
                headers: this.$store.getters.getHeader
            });
            const json = await response.json();
          
            const allDataCam = {
              batch: this.batch[0],
              sensor: this.sensors,
              data: [json]
            }
            this.$store.commit('setSensorsListCam', allDataCam)
          } finally { 
            this.$store.commit('decrementActiveRequests');
          }
        } 
      }
      //this.loading = false
      this.$store.commit('updateStateByBarn', {pk: this.barn.pk, sensor: 'smartcam'})
    },
    changeBarnTarget(barn, batch, sensors, route) {
      if (this.dataCam.length > 0) {
        this.$store.commit("setBarnTarget", barn)
        this.$store.commit("setBatchTarget", batch[0] == undefined ? null : batch[0])
        this.$router.push(route)
      }
    },
    dateCompare: function(first_date, second_date) {
      return Math.ceil(Math.abs(new Date(second_date) - new Date(first_date)) / (1000 * 60 * 60 * 24));
    },
  },
  computed: {
    smartCamText() {
      const today = new Date()
      const today_date = `${today.getFullYear()}-${(today.getMonth()+1)}-${today.getDate()}`
      if (this.dataCam.length === 0) return "SEM DADOS"
      if (this.batch[0].date_departure !== null) return "SEM LOTE"
      if (this.dateCompare(this.batch[0].date_accomodation, today_date) <= 29) return "SEM PESO"
      const weight = Math.round(this.dataCam[0].weight)
      return `${weight} kg | ${(this.percentage - 100).toFixed(1)}%`
    },
    percentage() {
      try {
        if (this.dataCam.length > 0 ) {
          return (this.dataCam[0].weight*100)/this.dataCam[0].reference_weight
        }
        else {
          return null
        }
      }
      catch(err) {
        return null
      }
    },
    smartCamColor() {
      if (this.smartCamText === "SEM CAM" || this.smartCamText === "EM BREVE") return 'no-sensor progress'
      if (this.smartCamText === "SEM LOTE")  return 'disable progress' 
      if (this.smartCamText === "SEM PESO")  return 'disable progress' 
      if (this.dataCam.length > 0) {
        const percentage = (Math.round(this.dataCam[0].weight)*100)/this.dataCam[0].reference_weight
        if (percentage > 105) {
          return 'peso-atual-acima progress'
        }
        if (percentage >= 100) {
          return 'peso-atual-bom progress'
        }
        if (percentage >= 95) {
          return 'peso-atual-medio progress'
        }
        if (percentage < 95) {
          return 'peso-atual-ruim progress'
        }
      }
      return 'disable progress'
    },
    stateLoadingBarn(){
      return this.$store.getters.getStateBarns.find((barn)=>{
        return barn.pk == this.barn.pk
      })
    },
    getDataCamSave(){
      return this.$store.getters.getSensorsListCam
    },
    selectSensorCam() {
      return this.$store.getters.getSensorsListCam.find(obj => obj.batch.pk == this.batch[0].pk)
    },
    dataCam(){
      return this.selectSensorCam?.data ?? []
    },
  },
  async created() {
    if (this.dataCam.length == 0) {
      await this.getDataCam()
      this.loading = false
    }  
    this.loading = false
  }
};
</script>

<style scoped>
.cursor{
  cursor: not-allowed;
}

.progress {
  border-radius: 0;
  height: 40px;
  background-color: #f4f4f4;
  font-weight: bold;
  font-size: 14px;
}

.progress-bar {
  text-align: left;
  padding-left: 10px;
}

.progress-bar span {
  color: #3e3e3e;
  background: url(../assets/listras.png) #fcfdfd;
  width: fit-content;
  padding: 0 10px;
  border-radius: 10px;
}

a {text-decoration: none;}
.peso-atual-acima .progress-bar {background: #415430;}
a .peso-atual-acima .progress-bar:hover {background: #273619;}
.peso-atual-bom .progress-bar {background: #a1cd7b;}
a .peso-atual-bom .progress-bar:hover {background: #5e7d42;}
.peso-atual-medio .progress-bar {background: #e2bf65;}
a .peso-atual-medio .progress-bar:hover {background: #c59e3a;}
.peso-atual-ruim .progress-bar {background: #a94949;}
a .peso-atual-ruim .progress-bar:hover {background: #952323;}
.no-sensor .progress-bar {background:  #f2f2f2; color: grey;}
a .no-sensor .progress-bar:hover {background: #cfcfcf;}
.disable .progress-bar {background:  #adadad;}
a .disable .progress-bar:hover {background: grey;}

.cam-data {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 

}

.cam-data:hover {
  animation: scaleAnimationCam 0.3s ease-in-out 25ms forwards; 
}

@keyframes scaleAnimationCam {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);

  }
}

.container-information-td{
  display: flex;
  align-items: center;
  justify-content: center;

}

.imgIcon{
  width: 20px;
  margin: 4px;
  margin-right: 8px;

}

.product-information {
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-size: 14px
}

.smartcam-state-information {
  padding-right: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.card-button {
  cursor: pointer;
}


@media screen and (min-width: 991px) {
  .imgIcon{
    display: none;
  }
}
</style>
