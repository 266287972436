<template>
  <tr>
    <td>
      <p>{{name}}</p>
    </td>
    <td>
      <p :class="totalColor">TOTAL: {{totalCount}}</p>
    </td>
    <SensorsColumn v-bind:item="item" v-bind:key="item.id" v-for="item in prediction.mic_data"/>
  </tr>
</template>

<script>
import SensorsColumn from "./SensorsColumn.vue"

export default {
  name: "CoughHistoryColumn",
  components: {
    SensorsColumn
  },
  props: ["prediction", "index"],
  computed: {
    name() {
      return this.prediction.day
    },
    totalCount() {
      const results = this.prediction.mic_data.some(item => item.coughs != null)
      if (!results) return "--"
      return Math.round(this.prediction.mic_data.reduce((acc, next) => acc + next.coughs, 0))
    },
    totalColor() {
      const results = this.prediction.mic_data.some(item => item.coughs != null)
      if (!results) return "total-color-neutral"
      
      const total = this.prediction.mic_data.reduce((current, next) => Math.max(current, next.coughs), 0)
      if (total >= 16) {
        return "total-color-bad"
      }
      if (total >= 8)  {
        return "total-color-medium"
      }  
      else {
        return "total-color-good"
      }
    }
  },
}
</script>

<style scoped>
.total-color-good {
  color: #50b300;
}
.total-color-medium {
  color: #f0b00e;
}
.total-color-bad {
  color: #dd4949;
}
.total-color-neutral {
  color: #b6b6b6;
}
</style>
