<template>
  <div class="header-component">
    <div class="header container">
      <div class="page-title">
        <h2 style="padding: 12px">{{ name }}</h2>
      </div>

      <div class="content-header">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["name"],
};
</script>

<style>
.header-component {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  background: var(--light-green);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.content-header{
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page-title {
  display: flex;
  align-items: center;
  color: #fff;
}

.page-title h2 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 25px;
  margin-bottom: 0;
}

.header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-buttons button,
.header-buttons form select{
  outline: none;
}

.register-btn {
  transition: 0.2s;
  float: right;
  border: none;
  background: none;
}

.history {
  border: solid 1px #fff;
  height: 36px;
  padding: 0 10px;
  background: transparent;
  color: #fff;
  border-radius: 2px;
  font-weight: 300;
}

.history-disabled {
  border: solid 1px #fff;
  height: 36px;
  padding: 0 10px;
  background: transparent;
  color: #fff;
  border-radius: 2px;
  font-weight: 300;
  cursor: not-allowed;
  outline: none;
}

.farm-dropdown select {
  border: solid 1px #fff;
  float: right;
  height: 36px;
  padding: 0 10px;
  background: transparent;
  color: #fff;
  margin: 0 8px;
  border-radius: 2px;
  font-weight: 300;
}

.farm-dropdown select option {
  color: #000;
}

.register-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}

.header-icon {
  transition: 0.2s;
  float: right;
  border: none;
  background: none;
  padding: 0 4px;
}

.header-button {
  border: solid 1px #fff;
  height: 36px;
  padding: 0 10px;
  background: transparent;
  color: #fff;
  border-radius: 2px;
  font-weight: 300;
}

.history-icon {
  height: 21.3px;
  filter: invert(100%) contrast(200%) brightness(200%);
  padding: 0 12px 0 0;
}

.history-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1192px) {
  .header-button {
    display: none;
  }
}

@media screen and (min-width: 1192px) {
  .header-icon {
    display: none;
  }
} 

@media screen and (max-width: 968px) {
  .header-component {
    padding: 4px 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page-title {
    padding: 12px;
    width: 100%;
  }

  .header-buttons {
    padding: 0 0 12px 0;
  }

  .page-title h2 {
    text-align: center;
    width: 100%;
    padding: 8px 0px 5px 0px;
    background: #6ba63c;
    border-radius: 50px;
  }
}

</style>
