<template>
  <div
    class="modal fade"
    id="foodModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <img src="../assets/ico-lapide.svg" /> Materia prima
          </h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <label>Barracão: {{barnName}}</label><br />

            <div class="tabs">
                <div class="tab tab-register" :class="change ? 'active' : ''"  @click="changeTab('change')">
                Trocar
                </div>
                <div class="tab" @click="changeTab('register')" :class="register ? 'active' : ''">
                Cadastrar
                </div>
            </div>
            <div>
                <div class="form-changed"  v-if="change">
                <!-- <label>Food: </label><br /> -->
                <select name="" class="form-control" id="" v-model="optionFood">
                    <option>Rações</option>
                    <option v-for="food in allFoodsList" :key="food.pk" :value="food">{{food.name}}</option>
                </select>

                <!-- <label>Silo: </label><br /> -->
                <select name="" class="form-control" id="" v-model="optionSilo">
                    <option>Silos</option>
                    <option v-for="silo in silosData" :key="silo.silo" :value="silo">{{silo.name}}</option>
                </select>

                <!-- <label>Data e Hora:</label><br /> -->
                <input
                type="date"
                class="form-control"
                v-model="date"

                required
                />
            </div>
            <div class="form-register" v-if="register">
                <!-- <label>Nova Ração: </label><br /> -->
                <input
                    type="text"
                    class="form-control"
                    placeholder="Food"
                    v-model="name"
                    required
                />

                <!-- <label>Densidade: </label><br /> -->
                <input
                    type="number"
                    class="form-control"
                    placeholder="Densidade"
                    v-model="density"
                    required
                />
                <div  class="checkbox-items">
                    <div v-for="(silo) in silosData" :key="silo.silo">
                        <label>
                        <input
                            type="checkbox"
                            v-model="checkedItems[silo.silo]"
                        />
                        {{ silo.name }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="container-button">
                <button type="submit" class="modal-btn" @click="send">{{register ? 'Cadastrar' : 'Trocar'}}</button>
            </div>
            </div>
            
          </form>
        </div>
      </div>
    </div>
    <SuccessAlert ref="successAlert" v-bind:message="messageSuccess"/>
    <ErrorAlert ref="errorAlert" v-bind:message="messageFailure"/>
  </div>
  <!-- end Cadastrar Baixa  -->
</template>

<script>
import ErrorAlert from "./ErrorAlert.vue";
import SuccessAlert from "./SuccessAlert.vue";

export default {
  name: "LossModal",
  components: {
    ErrorAlert,
    SuccessAlert
  },
  props: ['selectedFeed', 'allFood'],
  data() {
    return {
        name: '',
        density: '',
        silos:[],
        date: new Date().toISOString().substr(0, 10),
        optionFood: "Rações",
        optionSilo: "Silos",
        register: false,
        change: true,
        checkedItems: {},
    }
  },
  methods: {
    async registerFood(name, density, silos, company) {

        const selectedSilos = Object.keys(silos)
        .filter(id => silos[id])
        .map(Number);
      await this.$store.dispatch("registerFood", {name, density, selectedSilos, company})
      if (!(this.$store.getters.getError)) {
        this.$refs.errorAlert.closeAlert()
        this.$refs.successAlert.alert()
        this.handleClose()
      }
      else {
        this.$refs.successAlert.closeAlert()
        this.$refs.errorAlert.alert()
      }
    },
    async changeFood(food, company, date, silo) {
       
      const newDate = `${date}T00:00:00.000`  
       
      await this.$store.dispatch("changeFood", {food, company, newDate, silo})
      if (!(this.$store.getters.getError)) {
        this.$refs.errorAlert.closeAlert()
        this.$refs.successAlert.alert()
        this.handleClose()
      }
      else {
        this.$refs.successAlert.closeAlert()
        this.$refs.errorAlert.alert()
      }
    },
    handleClose() {
      this.$refs.closeButton.click();
    },
    changeTab(value){
        if (value == 'register') {
            this.register = true
            this.change = false
        } else {
            this.register = false
            this.change = true
        }
    },
    send (event) {
        event.preventDefault()
        if (this.change) {
            this.changeFood(this.optionFood.pk, this.company, this.date, this.optionSilo.silo)
        }else {
            this.registerFood(this.name, this.density, this.checkedItems, this.company)
        }
    },
  },
  computed: {
    messageSuccess(){
        return this.change ? "Troca realizada com sucesso" : "Cadastro realizado com sucesso"
    },
    messageFailure() {
        return this.change ? "Error ao trocar a ração" : "Error ao cadastrar a ração"
    },
    allFoodsList() {
        return this.allFood
    },
    barnName() {
      if (this.barn == null) return ""
      return `${this.barn.name}`
    },
    barn() {
      return this.$store.getters.getBarnTarget
    },
    batch() {
      return this.$store.getters.getBatchTarget
    },
    farm() {
      return this.$store.getters.getAllBarns.find((obj)=> {
        return obj.barn.find((barn)=>barn.pk == this.barn.pk)
      })
    },
    company() {
      return this.farm.farm.company
    },
    silosData () {
        return this.selectedFeed?.data ?? []
    }
  },
  created() {
    this.silosData.forEach(item => {
        this.$set(this.checkedItems, item.silo, true);
    });
  }
}
</script>

<style scoped>
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  padding-left: 10px;
}

select {
  margin: 0px;
  margin-top: 5px;
  padding-left: 0px;
}

.modal-body select {
  padding-left: 6px;
}

.modal-body label {
  padding-left: 12px;
}

.tabs {
  display: flex;
  border-radius: 5px;
  margin-top: 12px ;
}

.tab {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 5px;
  background-color: #e0e0e0;
  border-radius: 3px;
  cursor: pointer;
  border-top-right-radius: 8px;
  width: 50%;
}

.tab i {
  margin-right: 5px;
}

.tab:active {
  background-color: #c0c0c0;
}

.form-changed {
    margin-top: 24px;
    margin-bottom: 24px;
}

.form-register {
    margin-top: 24px;
    margin-bottom: 24px;
}

.form-control{
    margin-top: 12px;
}

.checkbox-items{
    display: flex;
}
.active{
    color: white;
    background-color: #78b843;
}

.container-button{
    display: flex;
    justify-content: end;
}
</style>
