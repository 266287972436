<template>
    <div>
        <div class="text"><span>Sensor online: </span>{{date}}</div>
    </div>
</template>


<script>

export default {
    props:['date']
}

</script>

<style scoped>
    .text {
        margin-top: 12px;
        font-size: 12px;
    }

    .text span{
        color: #78b843;
        font-weight:600 ;
    }
</style>
