<template>
  <div>
    <p>{{sensorsData.day}}</p>
    <span>{{cough_count}}</span>
  </div>
</template>

<script>
export default {
  name: "LastFiveDaysSmartMicCard",
  props: ["sensorsData"],
  computed: {
    cough_count() {
      const results = this.sensorsData.mic_data.some(item => item.coughs != null)
      if (!results) return "--"

      return Math.round(this.sensorsData.mic_data.reduce((acc, next) => acc + next.coughs, 0))
    }
  }
};
</script>
