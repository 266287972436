<template>
    <div class="body" :class="allScreen ? 'overlay' : ''">
        <div class="loader">
            <div class="corners">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="box">
                <p>P</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "PageLoader",
  props:['allScreen']
};
</script>

<style scoped>
.body {
  top: 0;
  left: 0;
  background-color: #f2f2f2;
  z-index: 999;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo opaco */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader {
  margin: auto;
  margin-top: 20vh;
  margin-bottom: 20vh;
  z-index: 999;
  position: relative;
  width:60px;
  height:60px;
  animation:scaling 2.5s ease infinite;
}
.loader .corners {
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  animation:loading 2.5s ease infinite;
}
.loader .corners span {
  position:absolute;
  width:20px;
  height:20px;
}
.loader .corners span:nth-child(1) {
  top:0px;
  left:0px;
  border-top:3px solid #78b843;
  border-left:3px solid #78b843;
}
.loader .corners span:nth-child(2) {
  top:0px;
  right:0px;
  border-top:3px solid #78b843;
  border-right:3px solid #78b843;
}
.loader .corners span:nth-child(3) {
  bottom:0px;
  right:0px;
  border-bottom:3px solid #78b843;
  border-right:3px solid #78b843;
}
.loader .corners span:nth-child(4) {
  bottom:0px;
  left:0px;
  border-bottom:3px solid #78b843;
  border-left:3px solid #78b843;
}
.loader .box {
  width:45px;
  height:45px;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
.loader .box p {
  margin-top: auto;
  color: #78b843;
  font-size: 40px;
  font-family: arial;
  position: absolute;
  top:-17%;
  left:25%;
}
@keyframes loading {
  50% {
    transform:rotate(135deg);
  }
  100% {
    transform:rotate(270deg);
  }
}
@keyframes scaling {
  0% {
    transform:scale(1.2);
  }
  50% {
    transform:scale(1.7);
  }
  100% {
    transform:scale(1.2);
  }
}
</style>
