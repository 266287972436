<template>
  <div>
    <Header v-bind:name="`${farmName} - ${barnName} ${batchName}`">

    </Header>

    <div class="detalhar-lote-fundo"></div>

    <div class="page-animation" >
      <BatchDetails v-bind:batch="batch" v-bind:barn="barn"/>
      <Copyright />
    </div>

    <ModalStyle>
      <EditBatchModal />
      <LossModal v-bind:barn="barn"/>
      <RegisterWeightModal v-bind:barn="barn"/>
    </ModalStyle>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import LossModal from "../components/LossModal.vue";
import RegisterWeightModal from "../components/RegisterWeightModal.vue";
import EditBatchModal from "../components/EditBatchModal.vue";
import Copyright from "../components/Copyright.vue";
import BatchDetails from "../components/BatchDetails.vue";
import ModalStyle from "../components/ModalStyle.vue";

export default {
  name: "BatchDetailsPage",
  beforeRouteEnter(to, from, next) {
    to.meta.title = "PecSmart - Detalhes do Lote";
    document.title = to.meta.title;
    next();
  },
  components: {
    Header,
    LossModal,
    Copyright,
    BatchDetails,
    EditBatchModal,
    ModalStyle,
    RegisterWeightModal,
  },
  methods: {
    batchHistory: function(value) {
      if (value === 'Histórico de Lotes') {
        this.$router.push('batches-history')
      }
    },
    Batch: function() {
      return this.$store.getters.getBatchToDetail
    }
  },
  computed: {
    barnName() {
      if (this.barn == null) {
        return ""
      }
      return `${this.barn.name}`
    },
    batchName() {
      if (this.batch == null) return ''
      if (this.batch?.date_departure !== null) return `- LOTE ${this.batch.pk} (finalizado)`
      if (this.batch?.date_departure == null) return `- LOTE ${this.batch.pk}`
      return ""
    },
    farmName() {
      if (this.barn) {
          return this.$store.getters.getAllBarns.find((obj)=> {
          return obj.barn.find((barn)=>barn.pk == this.barn.pk)
        }).farm.name
      }
      return ''
    },
    barn() {
      return this.$store.getters.getBarnTarget
    },
    batch() {
      return this.$store.getters.getBatchTarget
    },
  },
  data() {
    return {
      option: 'Lote n° 294850'
    }
  },
  created() {
    if (this.$store.getters.getLogedIn === false) {
      this.$router.push('/home-page')
    }
  }
};
</script>

<style>
.page-animation {
  animation:down 0.4s ease;
  width: 100%;
  padding-right:48px ;
  padding-left:48px ;
}

@media screen and (max-width: 540px) {
  .page-animation {
    padding-right:24px ;
    padding-left:24px ;
  }
}

@keyframes down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
