<script>
import { Scatter } from 'vue-chartjs'

export default {
  name: "SmartFeedLevelChart",
  extends: Scatter,
  props: ["sets", "text", "offset", "yAxesScale", "first_date", "last_date"],
  data() {
    return {
      weightData: {
        datasets: this.sets
      },
      options: {
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data.datasets[tooltipItem[0].datasetIndex].label
            },
            beforeLabel: function(tooltipItem) {
              return `Dia: ${tooltipItem.xLabel}`;
            },
            label: function(tooltipItem, data) {
              return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.yLabel}%`
            },
          },
          backgroundColor: '#fff',
          titleFontSize: 16,
          titleFontColor: '#89c25b',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          displayColors: false,
          borderColor: 'rgba(137, 194, 91, 1)',
          borderWidth: 2,
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        title: {
          display: true,
          text: this.text,
          fontSize: 16,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              offset: this.offset,
              scaleLabel: {
                display: false,
                fontSize: 16
              },
              gridLines: {
                color: "rgba(120, 184, 67, .125)",
                display: true,
              },
              type: 'time',
              time: {
                unit: 'day',
                stepSize: 1, // I'm using 3 hour intervals here
                tooltipFormat: 'DD/MM/YYYY HH:mm',
              },
              ticks: {
                min: this.first_date,
                max: this.last_date,
                major: {
                  enabled: true, // <-- This is the key line
                  fontStyle: 'bold', //You can also style these values differently
                  fontSize: 12 //You can also style these values differently
                },
              },
            },
          ],
          yAxes: [
            {
              display: this.yAxesScale,
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  return `${value} %`;
                }
              },
            },
          ],
        },
        layout:{
          padding: {
            left: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        stroke: {
          curve: 'straight'
        },
      }
    }
  },
  mounted () {
    this.renderChart(this.weightData, this.options)
  },
}
</script>
