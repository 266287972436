<template>
    <table class="rTable3">
      <thead>
        <tr>
          <th>
            <div>
              {{farm.name}}
            </div>
          </th>
          
           <th  class="thStyles">
            <div class="container-information-th"  v-if="showCam">
              <img class="imgIcon" src="../assets/kg.svg" />
              <div  style="text-align: center; margin-top:4px">CAM</div>
            </div>
          </th>
          <th class="thStyles">
            <div class="container-information-th" v-if="showMic">
              <img class="imgIcon" src="../assets/mic.svg" />
              <div style="text-align: center; margin-top:4px">MIC</div>
            </div>
          </th>
          <th class="thStyles">
            <div class="container-information-th" v-if="showFeed">
              <img class="imgIcon" src="../assets/silo.svg" />
              <div  style="text-align: center; margin-top:4px">FEED</div>
            </div>
          </th>
          <th class="thStyles" v-if="showBiButton">
            <div class="container-information-th">
              BI
            </div>
          </th>
        </tr>
      </thead>
      
      <tbody >
        <AnimadePlaceHolder v-if="loading" height="40px" width="100%" borderRadius="4px"/>
        <Barn @verifySensor="handleVerifySensor" :farm="farm" :barn="barn" :key="barn.pk" v-for="barn in dataBarns"/>
      </tbody>
    </table>
</template>

<script>
import Barn from './Barn.vue';
import { URL } from "../store/index"
import AnimadePlaceHolder from './AnimadePlaceHolder.vue'

export default {
  name: "Farm",
  components: {
    Barn,
    AnimadePlaceHolder,
  },
  data() {
    return {
      showModal: false,
      showModalEditFarm: false,
      dataBarns: [],
      loading: true,
      showMic : false,
      showCam : false,
      showFeed : true,
    }
  },
  props: ["farm", "index"],
  methods: {
    async fetchBarns() {
      let url_to_fetch = `${URL}barns/report/home/farm=${this.farm.farm}`
      await fetch(url_to_fetch, {
        headers: this.$store.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        this.dataBarns = json
        const barn = {
          farm: this.farm,
          barn: json
        }
        this.$store.commit('setAllBarns', barn)
        this.loading = false
      })
      .catch(err => console.log(err))
    },
    sensorsByType(type, type2) {
      if (this.dataSensors.length > 0) {
        if (type === 'smartmic') {
          return this.dataSensors.filter(sensor => sensor.type === type || sensor.type === type2)
        }
        return this.dataSensors.filter(sensor => sensor.type === type)
      }
      return []
    },
    handleVerifySensor(smartcam, smartmic, smartfeed) {
      this.showCam = smartcam.length > 0 ? true : false
      this.showMic = smartmic.length > 0 ? true : false
      this.showFeed = smartfeed.length > 0 ? true : false
    }
  },
  computed: {
    showBiButton() {
      const user = this.$store.getters.getCurrentUser
      return user.company === 47 && user.associated_farm === 64
    },
    userLevel() {
      return this.$store.getters.getUserLevel
    },
  },
   watch:{
    async farm() {
      if (this.userLevel === 'pecsmart') {
        this.$store.commit('cleanFazendas')
      }
      await this.fetchBarns()
    }
  },
  async created() {
     await this.fetchBarns()
  }
};

</script>

<style scoped>
.rTable3 {
  width: 100%;
  font-weight: 300;
}

.rTable3 thead {
  font-weight: bold;
  color: #3e3e3e;
  font-size: 14px;
}

.rTable3 tbody {
  background: #f4f4f4;
}

.rTable3 tr {
  border-bottom: solid 10px #fff;
}

.rTable3 thead a i {
  color: #3e3e3e;
}

.rTable3 thead a i:hover {
  color: #28a745;
  cursor: pointer;
}

.thead-column_space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.thead-column_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-information-th{
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgIcon{
  width: 20px;
  margin: 4px;
}

.thead-column p {
  margin: 0;
}

.thStyles{
  padding:0px 0px 0px 20px
}

#atualizar {
  border-radius: 8px;
  cursor: pointer;
  border: none;
  margin-top: 24px;
  text-transform: uppercase;
  background: #3e3e3e;
  font-weight: 300;
}

#atualizar:hover {
  background: #a1cd7b;
  border: none;
}

.grafico h2 {
  color: #3e3e3e;
  font-size: 14px;
  text-transform: uppercase;
}

.clear {
  clear: both;
}

.alert {
  border-radius: 8px;
  padding: 10px 10px;
  margin: 20px 0 -20px 0;
  text-align: center;
}

.alert p {
  margin: 0;
}

.card-body-icon {
  display: none;
}

.card-header {
  background: #1b4238;
  border: none;
  font-size: 20px;
  font-weight: 300;
}

.alert-warning {
  font-size: 17px;
  color: #3e3e3e;
  font-weight: 300;
}

.card-body {
  font-size: 17px;
  color: #3e3e3e;
  font-weight: 500;
}

.card {
  border: none;
}

.card-footer {
  border: none;
  font-size: 15px;
  color: #3e3e3e;
  font-weight: 300;
}

.card-btn {
  color: #fff;
  background-color: #78b843;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
}

.card-btn:hover {
  background-color: #1b4238;
  color: #fff;
  text-decoration: none;
}

.register-barn-btn {
  transition: 0.2s;
  float: right;
  border: none;
  outline: none;
  background: none;
}

.register-barn-btn:hover {
  transform: scale(1.2)
}

.text-semdados{
  font-weight: 600;
  height: 55px;
  text-align: center;
}

@media screen and ((max-width: 991px))  {
  .text-semdados {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 991px) {
  .rTable thead,
  .rTable2 thead,
  .rTable2 thead,
  .register-barn-btn,
  .rTable3 thead tr th:nth-child(2),
  .rTable3 thead tr th:nth-child(3),
  .rTable3 thead tr th:nth-child(4),
  .rTable3 thead tr th:nth-child(5),
  .rTable3 thead tr th:nth-child(6) {
    display: none;
  }

  .rTable tbody td,
  .rTable2 tbody td,
  .rTable3 tbody td,
  .rTable2 tbody td {
    display: flex;
    flex-direction: column;
  }

  .rTable .sensores,
  .rTable3 .sensores {
    display: flex;
    flex-direction: row;
  }

  .rTable .botoes,
  .rTable3 .botoes {
    display: flex;
    flex-direction: row;
  }

  .rTable th,
  .rTable td,
  .rTable2 th,
  .rTable2 td {
    border-bottom: dotted 1px #dadada;
  }

  .rTable tr,
  .rTable2 tr {
    border-bottom: solid 20px #fff;
  }

  .rTable tbody tr td:nth-child(1),
  .rTable tbody tr td:nth-child(2),
  .rTable tbody tr td:nth-child(3),
  .rTable tbody tr td:nth-child(4),
  .rTable tbody tr td:nth-child(5),
  .rTable tbody tr td:nth-child(6),
  .rTable tbody tr td:nth-child(7) {
    width: 100%;
  }

  .rTable tbody tr td:nth-child(1),
  .rTable2 tbody tr td:nth-child(1) {
    font-weight: 700;
    background: #404040;
    color: #fff;
    text-transform: uppercase;
  }

  .rTable3 thead tr th:nth-child(1) {
    font-weight: 700;
    background: #404040;
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
  }

  .rTable tbody tr td:nth-child(7) {
    padding-left: 0px;
  }
}

</style>
