<template>
  <div>
    <Header v-bind:name="name">
      <div class="header-buttons">
        <p class="sort-dropdown">Ordenar Por: </p>
        <form class="farm-dropdown">
          <HistorySort
          @sortByFarmName="sortByFarmName"
          @sortByBarnName="sortByBarnName"
          @sortByDateAccommodationIncreasing="sortByDateAccommodationIncreasing"
          @sortByDateAccommodationDecreasing="sortByDateAccommodationDecreasing"
          @sortByDateDepartureIncreasing="sortByDateDepartureIncreasing"
          @sortByDateDepartureDecreasing="sortByDateDepartureDecreasing"
          />
        </form>
      </div>
    </Header>


    <!-- HISTÓRICO DE LOTES -->
    <div class="container" v-if="!loading">
      <!-- Breadcrumbs-->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/home-page">Home</router-link>
        </li>
        <li class="breadcrumb-item active">Histórico de Lotes</li>
      </ol>

      <div class="row page-animation">
        <div class="col-lg-12">
          <div class="conteudo">
            <table class="rTable2">
              <thead>
                <tr>
                  <th>GRANJA</th>
                  <th>BARRACÃO</th>
                  <th>LOTE</th>
                  <th>INÍCIO</th>
                  <th>FIM</th>
                  <th>ANIMAIS</th>
                  <th></th>
                </tr>
              </thead>
              <PageLoader v-if="loadingFetch" :allScreen="true"/>
              <tbody>
                <OldBatch @activeLoading="toggeLoading" @desactiveLoading="toggeLoading" v-bind:batch="batch" v-bind:key="batch.pk" :hideBatchDetailTab="hideBatchDetailTab" v-for="batch in batch_list"/>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OldBatch from "../components/OldBatch.vue";
import Header from "../components/Header.vue";
import HistorySort from "../components/HistorySort.vue";
import PageLoader from "../components/Loader.vue";

export default {
  name: "BatchesHistoryPage",
  beforeRouteEnter(to, from, next) {
    to.meta.title = "PecSmart - Histórico de Lotes";
    document.title = to.meta.title;
    next();
  },
  components: {
    OldBatch,
    Header,
    HistorySort,
    PageLoader,
  },
  data() {
    return {
      name: "HISTÓRICO DE LOTES",
      batch_list: [],
      loading: true,
      loadingFetch: false
    }
  },
  methods: {
    allbatches() {
      this.batch_list = this.$store.getters.getHistoryBatch
    },
    filterBatches() {
      this.batch_list = this.batch_list.sort((a, b) => (a.date_accomodation > b.date_accomodation ? 1 : -1))
    },
    sortByFarmName(){
      this.batch_list.sort((a, b) => a.farm.name.localeCompare(b.farm.name))
    },
    sortByBarnName(){
      this.batch_list.sort((a, b) => a.barn.name.localeCompare(b.barn.name))
    },
    sortByDateAccommodationIncreasing(){
      this.batch_list = this.batch_list.sort((a, b) => (a.date_accomodation > b.date_accomodation ? 1 : -1))
    },
    sortByDateAccommodationDecreasing(){
      this.batch_list = this.batch_list.sort((a, b) => (a.date_accomodation < b.date_accomodation ? 1 : -1))
    },
    sortByDateDepartureIncreasing(){
      this.batch_list = this.batch_list.sort((a, b) => (a.date_departure > b.date_departure ? 1 : -1))
    },
    sortByDateDepartureDecreasing(){
      this.batch_list = this.batch_list.sort((a, b) => (a.date_departure < b.date_departure ? 1 : -1))
    },
    toggeLoading(value){
      this.loadingFetch = value
    }
  },
  computed: {
    allFarms() {
      return this.$store.getters.getAllBatches
    },
    companySettings(){
      return this.$store.getters.getCompanySettings 
    },
    hideBatchDetailTab() {
      if (this.userLevel == 'pecsmart') return true
      return !this.companySettings?.settings?.client_frontend?.hide_batch_details_tab ?? true
    },
    userLevel() {
      return this.$store.getters.getUserLevel
    },
  },
  async created() {
    this.$store.commit('cleanTarget')
    if (this.$store.getters.getLogedIn === false) {
      this.$router.push('home-page')
    }
    this.allbatches()
    this.sortByFarmName()
    this.loading = false
  }
};
</script>

<style scoped>
.sort-dropdown {
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}

.page-animation {
  animation:down 0.5s ease;
}
@keyframes down {
  0% {
    opacity: 0;
    transform:translateY(-50px);
  }
  100% {
    opacity: 1;
    transform:translateY(0px);
  }
}

.breadcrumb {
  background: none;
  padding: 20px 0;
  margin-bottom: 0;
  font-weight: 300;
}

.breadcrumb-item a {
  color: #6ba63c;
}

.breadcrumb-item a:hover {
  color: #6ba63c;
  text-decoration: underline;
}

.conteudo {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 40px;
}

.alert-success {
  display: block;
  clear: both;
  margin: 20px 0 0 0;
  padding: 10px;
  text-align: center;
  border: solid 2px #78b843;
  background: #e9f4df;
  border-radius: 8px;
}

.btn {
  border-radius: 0;
}

.btn-success {
  background-color: #a1cd7b;
  border: none;
}

.btn-success:hover {
  background: #1e7e34;
}

.btn-circle {
  width: 30px;
  height: 42px;
  padding: 0px 13px;
  font-size: 15px;
  line-height: 0;
  display: table-cell;
}


.rTable2 {
  width: 100%;
  font-weight: 300;
}
.rTable2 thead {
  font-weight: bold;
  color: #3e3e3e;
  font-size: 14px;
}
.rTable2 tbody {
  background: #f4f4f4;
}

.rTable2 tr {
  border-bottom: solid 10px #fff;
}


@media screen and (min-width: 993px) {
  .info-galpoes-mobile {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .rTable2 thead,
  .rTable2 thead {
    display: none;
  }
  .rTable2 tbody td,
  .rTable2 tbody td {
    display: flex;
    flex-direction: column;
  }
  .rTable2 th,
  .rTable2 td {
    border-bottom: dotted 1px #dadada;
  }
  .rTable2 tr {
    border-bottom: solid 20px #fff;
  }
  .rTable2 tbody tr td:nth-child(1),
  .rTable2 tbody tr td:nth-child(2),
  .rTable2 tbody tr td:nth-child(3),
  .rTable2 tbody tr td:nth-child(4),
  .rTable2 tbody tr td:nth-child(5),
  .rTable2 tbody tr td:nth-child(6) {
    width: 100%;
  }
  .rTable2 tbody tr td:nth-child(1) {
    font-weight: 700;
    background: #404040;
    color: #fff;
    text-transform: uppercase;
  }
  .info-galpoes-mobile {
    display: contents;
    font-weight: 500;
  }
  .btn-success {
    color: #000;
    background-color: #dcd9d9;
    border: none;
  }
  .btn-circle {
    padding: 11px 13px;
    font-size: 22px;
    width: 100%;
  }
  .btn-success:hover {
    background-color: #d0d0d0;
    color: #000;
  }
}

@media screen and (max-width: 380px) {
  .sort-dropdown {
    display: none;
  }
}

</style>
