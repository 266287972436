<template>
  <div>
    <Header v-bind:name="name">
      <form>
        <select name="granjas">
          <option value="">Barracão</option>
          <option value="">Barracão 1</option>
          <option value="">Barracão 2</option>
        </select>
      </form>

      <form>
        <select name="granjas">
          <option value="">Granja</option>
          <option value="">Nardelli</option>
          <option value="">Stupp</option>
        </select>
      </form>

      <p>Filtrar por:</p>
    </Header>

    <UserInformation />

    <Modals>
      <!-- MODALS FOR EDIT AND DELETE USER -->
    </Modals>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import UserInformation from "../components/UserInformation.vue";

export default {
  name: "ListUsersPage",
  components: {
    Header,
    UserInformation,
  },
  data() {
    return {
      name: "USUÁRIOS",
    };
  },
};
</script>

<style scoped>
.btn {
  border-radius: 0;
}
.btn-success {
  background-color: #a1cd7b;
  border: none;
}
.btn-success:hover {
  background: #1e7e34;
}
.btn-circle {
  width: 30px;
  height: 42px;
  padding: 0px 13px;
  font-size: 15px;
  line-height: 0;
  display: table-cell;
}

.rTable4 {
  width: 100%;
  font-weight: 300;
}
.rTable4 thead {
  font-weight: bold;
  color: #3e3e3e;
  font-size: 14px;
}
.rTable4 tbody {
  background: #f4f4f4;
}
.rTable4 tr {
  border-bottom: solid 10px #fff;
}

.rTable4 tbody tr td:nth-child(1) {
  width: 20%;
  padding: 10px;
}
.rTable4 tbody tr td:nth-child(2) {
  width: 25%;
  padding: 10px;
}
.rTable4 tbody tr td:nth-child(3) {
  width: 25%;
  padding: 10px;
}
.rTable4 tbody tr td:nth-child(4) {
  width: 10%;
  padding: 10px;
}
.rTable4 tbody tr td:nth-child(5) {
  width: 10%;
  padding: 10px;
}
.rTable4 tbody tr td:nth-child(6) {
  width: 10%;
}

.rTable4 .botoes a {
  border-right: solid 3px #f4f4f4;
}
.rTable4 .botoes a:nth-child(2) {
  background: #1b4238;
}
.rTable4 .botoes a:nth-child(2):hover {
  background: #28a745;
}
.rTable4 thead a i {
  color: #3e3e3e;
}
.rTable4 thead a i:hover {
  color: #28a745;
}
.galpoes .rTable3:last-child {
  margin-bottom: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 16px;
  margin-bottom: 0;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: -2px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #78b843;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

@media screen and (min-width: 993px) {
  .info-galpoes-mobile {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .rTable thead,
  .rTable2 thead,
  .rTable4 thead {
    display: none;
  }
  .rTable tbody td,
  .rTable2 tbody td,
  .rTable3 tbody td,
  .rTable4 tbody td {
    display: flex;
    flex-direction: column;
  }
  .rTable4 tbody tr td:nth-child(1),
  .rTable4 tbody tr td:nth-child(2),
  .rTable4 tbody tr td:nth-child(3),
  .rTable4 tbody tr td:nth-child(4),
  .rTable4 tbody tr td:nth-child(5),
  .rTable4 tbody tr td:nth-child(6) {
    width: 100%;
  }
  .rTable4 .botoes a:nth-child(2) {
    background: #dcd9d9;
  }
  .rTable4 .botoes a:last-child {
    border-right: none;
  }
  .rTable .botoes,
  .rTable4 .botoes {
    display: flex;
    flex-direction: row;
  }

  .info-galpoes-mobile {
    display: contents;
    font-weight: 500;
  }
  .btn-success {
    color: #000;
    background-color: #dcd9d9;
    border: none;
  }
  .btn-circle {
    padding: 11px 13px;
    font-size: 22px;
    width: 100%;
  }
  .btn-success:hover {
    background-color: #d0d0d0;
    color: #000;
  }
  .progress {
    background: none;
  }
}
</style>
