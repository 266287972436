<template>
  <div :class="postAlert" :style="style">
    <span class="msg">Erro: {{message}}</span>
    <div class="close-btn" v-on:click="closeAlert">
      <span class="fas">x</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorAlert",
  props: ["message"],
  data() {
    return {
      postAlert: "alert hide",
      style: ""
    };
  },
  methods: {
    alert: function () {
      this.postAlert = "alert show showAlert";
      this.style = "right: 20px;";
    },
    closeAlert: function () {
      this.postAlert = "alert hide";
      this.style = "";
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
*{
  margin: 0;
  padding: 0;
  user-select: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.alert{
  background: #ee5252;
  padding: 20px 40px 20px 0;
  min-width: 260px;
  position: absolute;
  bottom: 50px;
  border-radius: 4px;
  border-left: 8px solid #e22e2e;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.alert.showAlert{
  opacity: 1;
  pointer-events: auto;
}
.alert.show{
  animation: show_slide 1s ease forwards;
}
@keyframes show_slide {
  0%{
    transform: translateX(100%);
  }
  40%{
    transform: translateX(-10%);
  }
  80%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(-10px);
  }
}
.alert.hide{
  animation: hide_slide 1s ease forwards;
}
@keyframes hide_slide {
  0%{
    transform: translateX(-10px);
  }
  40%{
    transform: translateX(0%);
  }
  80%{
    transform: translateX(-10%);
  }
  100%{
    transform: translateX(100%);
  }
}
.alert .msg{
  padding: 0 20px;
  font-size: 18px;
  color: #ffffff;
}
.alert .close-btn{
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #e63232;
  padding: 20px 18px;
  cursor: pointer;
}
.alert .close-btn:hover{
  background: #d41c1c;
}
.alert .close-btn .fas{
  color:#8a1515;
  font-size: 22px;
  line-height: 40px;
}
@media screen and (max-width: 430px) {
  .alert .msg{
    padding: 0 20px;
    padding-left: 10px;
    font-size: 14px;
    color: #ffffff;
  }
}
@media screen and (max-width: 360px) {
  .alert .msg{
    padding: 0 20px;
    padding-left: 10px;
    font-size: 12px;
    color: #ffffff;
  }
}
</style>