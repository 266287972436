<template>
  <div
    class="modal fade"
    id="EndBatchModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <img class="register-icon" src="../assets/ico-edit.svg" /> {{title}}
          </h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="endBatch">
            <label>Lote: {{batchID}}</label><br />

            <label>Data de Encerramento Atual: {{ dateFormat(batch) }}</label>
            <br />
            <input
              name="date_departure"
              type="date"
              placeholder="dd-mm-yyyy"
              class="form-control"
              :value="date_departure"
              required
            />

            <label>Peso Final do Lote</label>
            <br />
            <input
              name="final_weight"
              type="number"
              step="any"
              class="form-control"
              :placeholder="final_weight"
              :value="final_weight"
              required
            />
            <button type="submit" :class="button">Atualizar</button>
          </form>
        </div>
      </div>
    </div>
    <SuccessAlert ref="successAlert" v-bind:message="messageSuccess" />
    <ErrorAlert ref="errorAlert" v-bind:message="messageFailure" />
  </div>
</template>

<script>
import ErrorAlert from "./ErrorAlert.vue";
import SuccessAlert from "./SuccessAlert.vue";
import { URL } from "../store/index"

export default {
  name: "EndBatchModal",
  components: {
    ErrorAlert,
    SuccessAlert,
  },
  data() {
    return {
      messageSuccess: "Lote finalizado",
      messageFailure: "Não foi possível finalizar o lote",
      button: "button",
    };
  },
  methods: {
    dateFormat(batch) {
      if (batch == null) return ""
      if (batch.date_departure == null) return "Lote Não Finalizado";

      let date = new Date(batch.date_departure);
      date.setDate(date.getDate() + 1);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      date = day + "/" + month + "/" + year;

      return `${date}`;
    },
    async endBatch(event) {
      if (this.button === "button-disable") return;

      const { date_departure, final_weight } = Object.fromEntries(new FormData(event.target))

      document.body.style.cursor = "progress";
      this.button = "button-disable";

      const batch = this.batch
      const final = Number(final_weight)
      const response = await this.$store.dispatch("endBatch", {batch, date_departure, final});

      if (response.ok) {
        this.$refs.errorAlert.closeAlert();
        this.$refs.successAlert.closeAlert();
        this.$refs.successAlert.alert();
        this.updateDataBatch()
        this.handleClose()

      } 
      else {
        this.$refs.errorAlert.closeAlert();
        this.$refs.successAlert.closeAlert();
        this.$refs.errorAlert.alert();
      }

      document.body.style.cursor = "default";
      this.button = "button";
    },
    handleClose() {
      this.$refs.closeButton.click();
    },
    async updateDataBatch(){
      try {
        let url_to_fetch = `${URL}batches/report/home/barn=${this.barn.pk}`
        await fetch(url_to_fetch, {headers: this.$store.getters.getHeader, cache: 'reload'})
        .then(response => response.json())
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    title() {
      if (this.batch == null) return ""
      if (this.batch.date_departure == null) return 'FINALIZAR LOTE'
      return 'EDITAR FINALIZAÇÃO'
    },
    date_departure() {
      if (this.batch == null) return ""
      return this.batch.date_departure
    },
    batchID() {
      if (this.batch == null) return ""
      return this.batch.pk == null ? `${this.batch.id}` : `${this.batch.pk}`
    },
    batch() {
      return this.$store.getters.getBatchTarget
    },
    barn() {
      return this.$store.getters.getBarnTarget;
    },
    final_weight() {
      if (this.batch == null) return ""
      if (this.batch.average_final_weight == null) return ""
      return this.batch.average_final_weight
    },
  }
};
</script>

<style scoped>
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  padding-left: 10px;
}

select {
  margin: 0px;
  margin-top: 5px;
  padding-left: 0px;
}

.modal-body select {
  padding-left: 6px;
}

.modal-body label {
  padding-left: 12px;
}

.register-icon {
  margin-bottom: 4.5px;
}

.button {
  color: #fff;
  background-color: #78b843;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
}

.button:hover {
  background-color: #1b4238;
  color: #fff;
  text-decoration: none;
}

.button-disable {
  color: #fff;
  background-color: #999999;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
  cursor: not-allowed;
  outline: none;
}
</style>
