<script>
import { Bar } from "vue-chartjs";
import Chart from "chart.js"

const color = (coughs) => {
  if (coughs == null) return "sensor-tosse-disable"
  if (coughs >= 16) return "sensor-tosse-ruim"
  if (coughs >= 8) return "sensor-tosse-medio"
  return "sensor-tosse-bom" 
}

Chart.Tooltip.positioners.custom = function(elements) {
  if (!elements.length) return false;
  let tooltip = document.getElementById('chartjs-tooltip');
  if (!tooltip) return false;
  return {
    x: (elements[0]._chart.width - tooltip.offsetWidth) / 2,
    y: -250,
  }
}

export default {
  extends: Bar,
  props: ["min_x", "max_x", "data"],
  data() {
    return {
      weightData: {
        datasets: this.data,
      },
      options: {
        title: {
          display: true,
          text: "GRÁFICO DE TOSSE",
          fontSize: 18,
        },
        legend: {
          display: true,
        },
        tooltips: {
          axis: 'x',
          mode: 'index',
          position: 'custom',
          enabled: false,
          custom: function (context) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');
            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = `<table></table>`;
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            const tooltipModel = context;
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || [];
              const bodyLines = tooltipModel.body.map(getBody);

              let innerHtml = '<thead>';
              titleLines.forEach(function (title) {
                innerHtml += `
                  <tr>
                    <th>
                      <div class="entrada-barracao">
                        <p>ENTRADA</p>
                        <p>${title}</p>
                      </div>
                    </th>
                  </tr>
                `;
              });
              innerHtml += '</thead>';

              innerHtml += '<tbody>';
              bodyLines.forEach(function (body, i) {
                const mic_coughs = body[0].substr(7)
                const span = `
                  <span>
                    <div class="conteudo smartmic">
                      <div class="smartmic-sensor-tosse sensor${i % 2}">
                        <div id="mic" style="text-align: center;">Mic: ${i + 1}</div>
                        <span></span><span></span>
                        <div class="bloco-info-sensor">
                          <p class="${color(mic_coughs)}" ></p>
                          <p>${mic_coughs}</p>
                        </div>
                        <span></span><span></span>
                      </div>
                    </div>
                  </span>
                `;
                innerHtml += `<tr style="display: inline-block;"><td>${span}</td></tr>`;
              });
              innerHtml += '</tbody>';
              innerHtml += '</div>';

              let tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = innerHtml;
            }

            let bar_chart = document.getElementById('bar-chart')
            const position = bar_chart.getBoundingClientRect()
            const bodyFont = Chart.helpers.fontString(tooltipModel);

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.font = bodyFont.string;
            tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          },
        },
        scales: {
          xAxes: [{
            type: 'time',
            ticks: {
              min: this.min_x,
              max: this.max_x,
            },
            time: {
              unit: 'day'
            },
            gridLines: {
              color: "rgba(120, 184, 67, .125)",
              display: true,
            },
            stacked: true,
            offset: true,
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              color: "rgba(120, 184, 67, .125)",
              display: true,
            },
            stacked: true,
          }]
        },
        layout: {
          padding: {
            left: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted() {
    this.renderChart(this.weightData, this.options)
  }
}
</script>
