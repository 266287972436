<template>
  <div class="tooltip-wrapper" @mouseenter="showTooltip" @mouseleave="hideTooltip">
    <slot></slot>
    <transition name="fade">
      <div v-if="visible" class="tooltip-content" >
        <p><span :id="color">{{title1}}</span>{{ p1 }}</p>
        <p><span :id="color">{{title2}}</span>{{ p2 }}</p>
        <p><span :id="color">{{title3}}</span>{{ p3 }}</p>
        <p><span :id="color">{{title4}}</span>{{ p4 }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    p1: {
        type: String,
        default: 'Info 1'
    },
    p2: {
        type: String,
        default: 'Info 2'
    },
    p3: {
        type: String,
        default: 'Info 3'
    },
    p4: {
        type: String,
        default: 'Info 4'
    },
    title1: {
        type: String,
        default: 'title1'
    },
    title2: {
        type: String,
        default: 'title2'
    },
    title3: {
        type: String,
        default: 'title3'
    },
    title4: {
        type: String,
        default: 'title4'
    },
    color: {
        type: String,
        default: ''
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    showTooltip() {
      this.visible = true;
    },
    hideTooltip() {
      this.visible = false;
    }
  }
};
</script>

<style scoped>
.tooltip-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip-content {
  position: absolute;
  bottom: 150%; 
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 16px;
  border-radius: 4px;
  z-index: 1000;
  white-space: nowrap;
  width: max-content;
}

.tooltip-content p {
    color: white;
    font-weight: 600;
}

#peso-feed-acima {color: #415430;}
#peso-feed-bom  {color: #a1cd7b;}
#peso-feed-medio {color: #e2bf65;}
#peso-feed-ruim {color: #ff4141;}
#feed-disabled {color:  #adadad;}


.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
