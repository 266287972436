<template>
  <div :class="sensors.length == 1 ? 'feed-data-one' : 'feed-data'">
    <AnimadePlaceHolder v-if="dataFeed.length == 0" height="40px" width="100%"  borderRadius="4px"/>
    <Tooltip
      v-else
      :p1="`${dataFeed[0].message != undefined ? 'SEM DADOS' : dataFeed[0].name}`"
      :p2="`${dataFeed[0].message != undefined ? 'SEM DADOS' : dataFeed[0].last_hour_level} %`"
      :p3="`${formatDate(dataFeed[0].datetime)}`"
      :p4="`${formatDate(dataFeed[0].datetime_last_ping)}`"
      title1="Nome: "
      title2="Nivel: "
      title3="Medido: "
      title4="Online: "
      :color="smartFeedColor(dataFeed[0])"
    >
      <div
        class="progress-bar progress"
        style="width: 100%; padding: 0; margin: 0 2px; border-radius: 4px"
        :class="`${smartFeedColor(dataFeed[0])}`"
      >
        <div class="container-information-td">
          <p :class="dataFeed[0].message != undefined ? 'fonteSenlote' : 'product-information'">
            <span v-if="dataFeed[0].message == undefined" :id="smartFeedColor(dataFeed[0], true)">{{`${dataFeed[0].name}:`}}</span> {{' '}} {{ dataFeed[0].message != undefined ? 'SEM DADOS' : ` ${dataFeed[0].last_hour_level} %` }}
          </p>
        </div>
      </div>
    </Tooltip>  
  </div>
</template>

<script>
import  Tooltip  from '../components/Tooltip.vue';
import { URL } from "../store/index"
import AnimadePlaceHolder from './AnimadePlaceHolder.vue'
import { format, parseISO } from 'date-fns'

export default {
  name: "SensorsFeed",
  components: {
    AnimadePlaceHolder,
    Tooltip,
  },
  data() {
    return {
      dataFeedFromFetch:[],
      loading: true
    }
  },
  props: ["sensors", "indexBarn", 'barn', 'batch', 'feed'],
  methods:{
    formatDate (date) {
      if (date) {
          return format(parseISO(date), 'dd-MM-yyyy hh:mm')
      } else {
          return 'SEM DADOS'
      }
    },
   smartFeedColor(feed, colorText) {
      if (colorText) {
        if (feed.message !== undefined) return 'feed-disabled-text'
        if (this.batch[0]?.date_departure != null) return 'feed-disabled-text'
        if (feed.last_hour_level > 30) {
          return 'peso-feed-bom-text'
        }
        if (feed.last_hour_level > 15) {
          return 'peso-feed-medio-text'
        }
        if (feed.message) {
          return 'feed-disabled-text'
        }
        return 'peso-feed-ruim-text'
      }
      if ( this.batch?.message) {
        if (feed.last_hour_level > 30) {
          return 'peso-feed-bom'
        }
        if (feed.last_hour_level > 15) {
          return 'peso-feed-medio'
        }
        if (feed.message) {
          return 'feed-disabled'
        }
        return 'peso-feed-ruim'
      } 
      if (feed.message !== undefined) return 'feed-disabled'
      if (this.batch[0]?.date_departure != null) return 'feed-disabled'
      if (feed.last_hour_level > 30) {
        return 'peso-feed-bom'
      }
      if (feed.last_hour_level > 15) {
        return 'peso-feed-medio'
      }
      return 'peso-feed-ruim'
    },
    changeBarnTarget(barn, batch, sensors, route) {
      if (this.dataFeed.length > 0) {
        this.$store.commit("setBarnTarget", barn)
        this.$store.commit("setBatchTarget", batch[0] == undefined ? null : batch[0])
        this.$router.push(route)
      }
    },
    async getDataFeed() {
      try {
        let url_to_fetch = `${URL}sensors/report/data/home/smartfeed/smartfeed_pk=${this.feed.pk}`;
        const response = await fetch(url_to_fetch, {
            headers: this.$store.getters.getHeader
        });
        const json = await response.json();
        if (json[0] == undefined) {
          this.dataFeedFromFetch.push(json);
        } else {
          this.dataFeedFromFetch.push(json[0]);
        }

        const allDataFeed = {
          barn: this.barn,
          batch: this.batch[0],
          sensor: this.sensors,
          data:this.dataFeedFromFetch
        }

        this.$store.commit('setSensorsListFeed', allDataFeed)
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed:{
    dataFeedBySensor() {
      const listbyBarn = this.$store.getters.getSensorsListFeed.filter((feed)=>{
        return feed.barn.pk == this.barn.pk
      })
      if (listbyBarn[0]) {
          return listbyBarn[0].data.find((feed)=>{
            return feed.sensor == this.feed.pk
        })
      } else {
        return undefined
      }
    },
    dataFeed() {
      if (this.dataFeedBySensor) return [this.dataFeedBySensor]
      return this.dataFeedFromFetch ?? []
    },
  },
  async created() {
    if (!this.dataFeedBySensor) {
      await this.getDataFeed()
    }
  }
};

</script>

<style scoped>
.cursor{
  cursor: not-allowed;
}

.progress {
  border-radius: 0;
  height: 40px;
  background-color: #f4f4f4;
  font-weight: bold;
  font-size: 14px;
}

.progress-bar {
  text-align: left;
  padding-left: 10px;
}


a {text-decoration: none;}
.peso-atual-acima .progress-bar {background: #415430;}
a .peso-atual-acima .progress-bar:hover {background: #273619;}
.peso-atual-bom .progress-bar {background: #a1cd7b;}
a .peso-atual-bom .progress-bar:hover {background: #5e7d42;}
.peso-atual-medio .progress-bar {background: #e2bf65;}
a .peso-atual-medio .progress-bar:hover {background: #c59e3a;}
.peso-atual-ruim .progress-bar {background: #a94949;}
a .peso-atual-ruim .progress-bar:hover {background: #952323;}
.no-sensor .progress-bar {background:  #f2f2f2; color: grey;}
a .no-sensor .progress-bar:hover {background: #cfcfcf;}
.disable .progress-bar {background:  #adadad;}
a .disable .progress-bar:hover {background: grey;}

.feed-data-wrapper {
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.feed-data { 
  flex: 1 1 calc(50% - 5px); 
  max-width: calc(50% - 5px); 
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 
}

.feed-data-one {
  flex: 1 1 calc(100% - 10px); 
  max-width: calc(100% - 10px); 
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 
}

.fonteSenlote{
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-size: 12px
}

.container-information-td{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.imgIcon{
  width: 20px;
  margin: 4px;
  margin-right: 8px;

}

.feed-data {
  width: -moz-available;
  width: -webkit-fill-available;
}

.peso-feed-acima {background: #415430;}
.peso-feed-acima:hover {background: #273619;}
.peso-feed-bom {background: #a1cd7b;}
.peso-feed-bom:hover {background: #5e7d42;}
.peso-feed-medio {background: #e2bf65;}
.peso-feed-medio:hover {background: #c59e3a;}
.peso-feed-ruim {background: #c25757;}
.peso-feed-ruim:hover {background: #952323;}
.feed-disabled {background:  #adadad;}
.feed-disabled:hover {background: grey;}

.product-information {
  margin: 0px;
  padding: 0px;
  font-size: 12px
}

.product-information span{
  margin: 0px;
  margin-right: 4px;
  padding: 0px;
  font-size: 12px;
  font-weight: 700;
}


#peso-feed-bom-text{
  color: #003d03;
}

#peso-feed-medio-text {
  color:#3d3900;
}

#feed-disabled-text {
  color: white
}

#peso-feed-ruim-text{
  color:#3d0000; 
}

.card-button {
  cursor: pointer;
}

@media screen and (min-width: 991px) {
  .imgIcon{
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .product-information {
    font-size: 10px
  }
  .product-information span{
    font-size: 10px
  }
}
</style>