<template>
  <tr>
    <td>{{barn.name}}</td>
    <td class="cam-data"> 
      <div v-if="sensorsByType('smartcam').length > 0">
        <AnimadePlaceHolder v-if="loadingBatch" height="40px" width="100%"  borderRadius="4px"/>
        <SensorsCam v-else  :barn="barn" :sensors="sensorsByType('smartcam')" :batch="dataBatch"/>
      </div>
    </td>
    <td class="mic-data" >
      <div v-if="sensorsByType('smartmic', 'smartmic_central_V3').length > 0">
        <AnimadePlaceHolder v-if="loadingBatch" height="40px" width="100%"  borderRadius="4px"/>
        <SensorsMic v-else :barn="barn" :batch="dataBatch" :sensors="sensorsByType('smartmic', 'smartmic_central_V3')"/> 
      </div>
    </td>
    <td >
      <div v-if="sensorsByType('smartfeed').length > 0">
        <AnimadePlaceHolder v-if="loadingBatch" height="40px" width="100%" borderRadius="4px"/>
        <SensorsFeed v-else :barn="barn" :batch="dataBatch" :sensors="sensorsByType('smartfeed')"/>
      </div>
    </td>
    <td v-if="showBiButton" class="mic-data">
      <div style="width: 100%; padding: 0; margin: 0 2px; border-radius: 4px"  >
        <a  @click="changeBarnTarget(barn, dataBatch, '/batch-details/embedded-bi')" class="card-button">
          <div class="container-information-td progress" :class="loadingTotal !== 0 ? 'cursor' : ''">
            <p class="product-information">
              BI
            </p>
          </div>
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import SensorsFeed from './SensorsFeed.vue';
import SensorsMic from './SensorsMic.vue';
import { URL } from "../store/index"
import SensorsCam from './SensorsCam.vue';
import AnimadePlaceHolder from './AnimadePlaceHolder.vue'

export default {
  name: "Barn",
  components: {
  SensorsFeed,
  SensorsMic,
  SensorsCam,
  AnimadePlaceHolder,
  
  },
  data() {
    return {
      dataSensors: [],
      dataBatch: [],
      loadingSensor: true,
      loadingBatch: true
    }
  },
  props: ["barn", "indexBarn", "farm"],
  computed: {
    showBiButton() {
      const user = this.$store.getters.getCurrentUser
      return user.company === 47 && user.associated_farm === 64
    },
    loadingTotal() {
      return this.$store.getters.getActiveRequest
    },
    stateBarn(){
      return  { pk: this.barn.pk}
    }
  },
  methods: {
    async fetchBatch() {
      try {
      this.$store.commit('incrementActiveRequests');
      let url_to_fetch = `${URL}batches/report/home/barn=${this.barn.pk}`
      await fetch(url_to_fetch, {headers: this.$store.getters.getHeader})
      .then(response => response.json())
      .then(json => {
        this.dataBatch = json
        this.$emit('verifySensor', 
          this.sensorsByType('smartcam'),
          this.sensorsByType('smartmic', 'smartmic_central_V3'),
          this.sensorsByType('smartfeed')
        )
        if (json.length > 0) {
          const oldBatch = json.map(element => {
            return {
              ...element,
              farm: this.farm,
              barn: this.barn
            }
          });
          this.$store.commit('setHistoryBatch', oldBatch)
        }   
      })
      } finally {
        this.$store.commit('decrementActiveRequests');
      }
      this.loadingBatch = false
    },
    async sensorsFetch() {
      try {
        this.$store.commit('incrementActiveRequests');
        let url_to_fetch = `${URL}sensors/report/home/barn=${this.barn.pk}/`;
        let response = await fetch(url_to_fetch, {
          headers: this.$store.getters.getHeader
        });
        this.dataSensors = await response.json();
        this.loadingSensor = false
      } catch (error) {
        return 
      } finally {
        this.$store.commit('decrementActiveRequests');
      }
      
    },
    sensorsByType(type, type2) {
      if (this.dataSensors.length > 0) {
        if (type === 'smartmic') {
          return this.dataSensors.filter(sensor => sensor.type === type || sensor.type === type2)
        }
        return this.dataSensors.filter(sensor => sensor.type === type)
      }
      return []
    },
    changeBarnTarget(barn, batch, route) {
      if (this.userRole === 'farm_employee' && route === '/batch-details/smart-cam') return;
      this.$store.commit("setBarnTarget", barn)
      this.$store.commit("setBatchTarget", batch[0])
      this.$router.push(route)
    },
  },
  async created() {
    await this.sensorsFetch()
    await this.fetchBatch()
  }
};

</script>

<style scoped>
.cursor{
  cursor: not-allowed;
}

.rTable {
  width: 100%;
  font-weight: 300;
}

.rTable thead {
  font-weight: bold;
  color: #3e3e3e;
  font-size: 14px;
}

.rTable tbody {
  background: #f4f4f4;
}

.rTable tr {
  border-bottom: solid 10px #fff;
}

.btn {
  border-radius: 0;
}

.btn-success {
  background-color: #a1cd7b;
  border: none;
}

.btn-success:hover {
  background: #1e7e34;
}

.btn-disable {
  background-color: #bebebe;
  border: none;
}

.btn-disable:hover {
  background: #8b8b8b;
}

.btn-circle {
  width: 30px;
  height: 42px;
  padding: 0px 13px;
  font-size: 15px;
  line-height: 0;
  display: table-cell;
}

.rTable tbody tr td:nth-child(1){width:19%; padding: 10px;}
.rTable tbody tr td:nth-child(2){width:19%; padding: 10px;}
.rTable tbody tr td:nth-child(3){width:11%; padding: 10px;}
.rTable tbody tr td:nth-child(4){width:11%; padding: 10px;}
.rTable tbody tr td:nth-child(5){width:5%; padding: 10px;}
.rTable tbody tr td:nth-child(6){width:4%;}

.feed-data {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 

}

.feed-data:hover {
  animation: scaleAnimation 0.3s ease-in-out 25ms forwards; 
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);

  }
}

.cam-data {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 

}

.cam-data:hover {
  animation: scaleAnimationCam 0.3s ease-in-out 25ms forwards; 
}

@keyframes scaleAnimationCam {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);

  }
}

.mic-data {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 

}

.mic-data:hover {
  animation: scaleAnimationMic 0.3s ease-in-out 25ms forwards; 
}

@keyframes scaleAnimationMic {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);

  }
}

.rTable3 .botoes a {
  border-right: solid 3px #f4f4f4;
}

.rTable3 .botoes a:nth-child(3) {
  background: #1b4238;
}

.rTable3 .botoes a:nth-child(3):hover {
  background: #28a745;
}

.rTable3 tbody tr td:nth-child(1){
  width: 30%;
  padding: 10px 0 10px 10px;
  overflow: hidden;
}

.rTable3 tbody tr td{
  height: 70px;
}

.rTable3 tbody tr td:nth-child(2){width:15%; padding: 10px 0 10px 20px;}
.rTable3 tbody tr td:nth-child(3){width:15%; padding: 10px 0 10px 20px;}
.rTable3 tbody tr td:nth-child(4){height: 100%; padding: 10px 10px 10px 20px;}
.rTable3 tbody tr td:nth-child(5){ 
  padding: 10px 10px 10px 20px;
}

.card-button {
  cursor: pointer;
}
a {text-decoration: none;}

.container-information-td{
  display: flex;
  justify-content: center;
}

.progress {
  border-radius: 4px;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  background-color: #a1cd7b;
}

.product-information {
  margin: 0px;
  padding: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (min-width: 991px) {
  .imgIcon{
    display: none;
  }
}
@media screen and (min-width: 993px) {
  .info-galpoes-mobile {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .rTable thead,
  .rTable2 thead,
  .rTable2 thead {
    display: none;
  }

@media screen and (max-width: 991px) {
  .rTable3 tbody tr td:nth-child(2){width:15%; padding: 10px 0 10px 0px;}
  .rTable3 tbody tr td:nth-child(3){width:15%; padding: 10px 0 10px 0px;}
  .rTable3 tbody tr td:nth-child(4){ padding: 10px 0px 10px 0px;}
  .rTable3 tbody tr td:nth-child(5){ padding: 10px 0px 10px 0px;}
  .rTable3 tbody tr td:nth-child(6){ padding: 10px 0px 10px 0px;}
}

  .rTable tbody td,
  .rTable2 tbody td,
  .rTable3 tbody td,
  .rTable2 tbody td {
    display: flex;
    flex-direction: column;
  }

  .rTable .sensores,
  .rTable3 .sensores {
    display: flex;
    flex-direction: row;
  }

  .rTable .botoes,
  .rTable3 .botoes {
    display: flex;
    flex-direction: row;
  }

  .rTable th,
  .rTable td,
  .rTable2 th,
  .rTable2 td {
    border-bottom: dotted 1px #dadada;
  }

  .rTable tr,
  .rTable2 tr {
    border-bottom: solid 20px #fff;
  }

  .rTable tbody tr td:nth-child(1),
  .rTable tbody tr td:nth-child(2),
  .rTable tbody tr td:nth-child(3),
  .rTable tbody tr td:nth-child(4),
  .rTable tbody tr td:nth-child(5),
  .rTable tbody tr td:nth-child(6),
  .rTable tbody tr td:nth-child(7) {
    width: 100%;
  }

  .rTable2 tbody tr td:nth-child(1),
  .rTable2 tbody tr td:nth-child(2),
  .rTable2 tbody tr td:nth-child(3),
  .rTable2 tbody tr td:nth-child(4),
  .rTable2 tbody tr td:nth-child(5),
  .rTable2 tbody tr td:nth-child(6) {
    width: 100%;
  }

  .rTable3 tbody tr td:nth-child(1),
  .rTable3 tbody tr td:nth-child(2),
  .rTable3 tbody tr td:nth-child(3),
  .rTable3 tbody tr td:nth-child(4),
  .rTable3 tbody tr td:nth-child(5) {
    width: 100%;
  }

  .rTable3 tbody tr td:nth-child(4) img {
    width: 10%;
    max-height: 20px;
  }

  .rTable tbody tr td:nth-child(1),
  .rTable2 tbody tr td:nth-child(1) {
    font-weight: 700;
    background: #404040;
    color: #fff;
    text-transform: uppercase;
  }

  .rTable tbody tr td:nth-child(7) {
    padding-left: 0px;
  }

  .rTable3 .botoes a:nth-child(3) {
    background: #dcd9d9;
  }

  .rTable3 .botoes a:last-child {
    border-right: none;
  }

  .btn-success {
    color: #fff;
    background-color: #a1cd7b;
    border: none;
  }

  .btn-circle {
    padding: 11px 13px;
    font-size: 22px;
    width: 100%;
  }

  .btn-success:hover {
    background-color: #1e7e34;
    color: #fff;
  }
}

.bi-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 42px;
  margin-right: 4px;
}




</style>
