<template>
    <td :class="color"><span>Sensor 0{{item.mic}}:</span>
      <p style="color: black;">{{cough_count}}</p>
    </td>
</template>

<script>
export default {
  name: "SensorsRow",
  props: ["item"],
  computed: {
    color() {
      if (this.item.coughs == null) return "total-color-neutral"
      if (this.item.coughs >= 16) return "total-color-bad"
      if (this.item.coughs >= 8) return "total-color-medium"
      return "total-color-good"
    },
    cough_count() {
      if (this.item.coughs == null) return "--"
      return this.item.coughs
    }
  },
}
</script>
<style scoped>
.total-color-good {
  background: #9bd46c;
}
.total-color-medium {
  background: #e2bf65;
}
.total-color-bad {
  background: #db6969;
}
.total-color-neutral {
  background: #dcd9d9;
}
</style>
