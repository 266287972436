<template>
    <div class="tab-lote-detalhado">
      <ul class="nav nav-tabs container">
        <li class="nav-item" v-if="hideBatchDetailTab">
          <router-link style="border-radius: 10px 10px 0 0;" :to="{ name: 'BatchTab', params: { barn: barn, batch: batch }}" class="nav-link" :class="{'active': batchTabActive}" data-toggle="tab">
            <a>
              <img style="filter: invert(100%);" src="../assets/ico-cadastro.svg" />
              <p>DETALHES DO LOTE</p>
            </a>
          </router-link>
        </li>

        <li v-if="selectSensorCam !== undefined" class="nav-item">
          <router-link style="border-radius: 10px 10px 0 0;" :to="{ name: 'SmartCamPage', params: { barn: barn, batch: batch }}" class="nav-link" :class="{'active': smartCamActive}" data-toggle="tab">
            <a>
              <img src="../assets/kg.svg" />
              <p>SMARTCAM</p>
            </a>
          </router-link>
        </li>

        <li v-if="selectSensorMic !== undefined" class="nav-item">
          <router-link style="border-radius: 10px 10px 0 0;" :to="{ name: 'SmartMic', params: { barn: barn, batch: batch }}" class="nav-link" :class="{'active': smartMicActive}" data-toggle="tab">
            <a>
              <img src="../assets/mic.svg" />
              <p>SMARTMIC</p>
            </a>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link style="border-radius: 10px 10px 0 0;" :to="{ name: 'SmartFeedPage', params: { barn: barn, batch: batch }}" class="nav-link" :class="{'active': smartFeedActive}" data-toggle="tab">
            <a>
              <img src="../assets/silo.svg" />
              <p>SMARTFEED</p>
            </a>
          </router-link>
        </li>

        <li v-if="showBiButton" class="nav-item">
          <router-link style="border-radius: 10px 10px 0 0;" :to="{ name: 'EmbeddedBI', params: { barn: barn, batch: batch }}" class="nav-link" :class="{'active': embedBiActive}" data-toggle="tab">
            <a>
              <p>BI</p>
            </a>
          </router-link>
        </li>
      </ul>

      <div class="tab-content">
        <transition name="fade" mode="out-in">
          <router-view @newBatchData="restoreBatch"></router-view>
        </transition>
      </div>
    </div>
</template>

<script>

export default {
  name: "BatchDetails",
  props: ["batch", "barn"],
  methods: {
    restoreBatch(value) {
      this.$emit('newBatchData', value)
    },
    sensors: function(type) {
      try {
        return this.barn.sensors.filter(sensor => sensor.type === type)
      }
      catch(err) {
        return []
      }
    }
  },
  computed: {
    batchTabActive() {
      if (this.$route.name === 'BatchTab') return true;
      return false;
    },
    smartCamActive() {
      if (this.$route.name === 'SmartCamPage') return true;
      return false;
    },
    smartMicActive() {
      if (this.$route.name === 'SmartMic') return true;
      return false;
    },
    smartFeedActive() {
      if (this.$route.name === 'SmartFeedPage') return true;
      return false;
    },
    embedBiActive() {
      if (this.$route.name === 'EmbeddedBI') return true;
      return false;
    },
    userRole() {
      return this.$store.getters.getCurrentUser.role
    },
    batchTarget() {
      return this.$store.getters.getBatchTarget
    },
    showBiButton() {
      // TODO: Change this to get information from the backend about using the button or not
      const user = this.$store.getters.getCurrentUser
      return user.company === 47 && user.associated_farm === 64
    },
    listFeed() {
      return this.$store.getters.getSensorsListFeed
    },
    listCam() {
      return this.$store.getters.getSensorsListCam
    },
    listMic() {
      return this.$store.getters.getSensorsListMic
    },
    selectSensorMic() {
      return this.listMic.find(obj => obj.batch.pk == this.batchTarget?.pk)
    },
    selectSensorCam() {
      return this.listCam.find(obj => obj.batch.pk == this.batchTarget?.pk)
    },
    selectSensorFeed() {
      return this.listFeed.find(obj => obj.batch.pk == this.batchTarget?.pk)
    },
    companySettings(){
      return this.$store.getters.getCompanySettings 
    },
    farm() {
      return this.$store.getters.getAllBarns.find((obj)=> {
        return obj.barn.find((barn)=>barn.pk == this.barn.pk)
      })
    },
    company() {
      return this.farm.farm.company
    },
    hideBatchDetailTab() {
      if (this.userLevel == 'pecsmart') return true
      return !this.companySettings?.settings?.client_frontend?.hide_batch_details_tab ?? true
    },
    userLevel() {
      return this.$store.getters.getUserLevel
    },
  },
  async created() {
    if (this.userLevel == 'pecsmart') {
      await this.$store.dispatch('fetchCompanySettings', this.company)
      this.loading = false
    }
    this.loading = false
  }
};
</script>

<style>
.tab {
  border-radius: 10px 10px 0 0;
}

.fade-enter-active,
.fade-leave {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.edit-batch-button {
  padding: 0px 10px 0px 10px;
}

.detalhar-lote-fundo {
  background: #FFF;
  height: 70px;
  width: 100%;
  margin-bottom: -47px;
  box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.detalhar-lote-botoes {
  display: flex;
  align-content: center;
  justify-content: center;
}

.detalhar-lote-botoes a {
  float: right;
  border-radius: 8px;
  margin-top: 24px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 6px 15px;
  font-size: 16px;
}
.detalhar-lote-botoes a:nth-child(1) {
  background: #1B4238;
  color: #FFF;
}
.detalhar-lote-botoes a:nth-child(2) {
  background: #78B843;
  color: #FFF;
}
.detalhar-lote-botoes a:hover {
  background: #a1cd7b;
}

#smartfeed {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#smartfeed h2 {
  font-size: 14px;
}
#smartfeed .conteudo, #smartfeed .col-lg-8 {
  margin-top: 30px;
}
.smartfeed-info div {
background: #FFF;
border-radius: 8px;
float: left;
padding: 12px 20px;
margin-right: 1%;
}
.smartfeed-info p {
color: #c3c3c3;
font-weight: 300;
font-size: 16px;
margin: 0 0 5px 0;
}
.smartfeed-info span {
color: white;
font-weight: 700;
font-size: 20px;
}
.smartfeed-silo p {
text-align: center;
color: #FFF;
background: #3e3e3e;
margin: 0 auto;
position: relative;
padding: 5px;
border: solid 2px #6c6c6c;
}
.progress-bar-vertical {
width: 100% !important;
min-height: 222px;
border: solid 5px #3e3e3e;
display: flex;
align-items: flex-end;
}
.progress-bar-vertical .progress-bar {
width: 100%;
height: 0;
-webkit-transition: height 0.6s ease;
-o-transition: height 0.6s ease;
transition: height 0.6s ease;
}
.nivel-atual-bom .progress-bar {background: #78B843;}
.nivel-atual-medio .progress-bar {background: #D6A424;}
.nivel-atual-ruim .progress-bar {background: #A94949;}
.disabled .progress-bar {background: #adadad;}


.tab-lote-detalhado {
  margin-bottom: 20px;
  margin-top: 7px;
}
.tab-lote-detalhado .nav-link:hover {
  border: none;
  background: #DEDEDE;
}
.tab-lote-detalhado .nav-link {
  color: #3E3E3E;
  background: #DEDEDE;
  margin-right: 3px;
  border: none;
  border-radius: 0px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
color: #3e3e3e;
background-color: #f2f2f2;
border: none;
}
.nav-tabs {
border-bottom: none;
background: #FFF;
}
.nav-tabs .nav-item {
margin-bottom: 0px;
}

.nav-item a {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.nav-item a img {
  width: 20px;
}

.nav-item a p {
  margin-top: 0;
  margin-bottom: 0;
}

.select-lote {
background: #78b843;
padding: 8px 15px 7px 15px;
color: #FFF;
border: solid 1px #FFF;
border-radius: 2px;
float: right;
margin-top: -8px;
}
.detalhar-lote {
  padding-top: 40px;
  font-size: 16px;
  font-weight: 300;
  flex-wrap: wrap;
  display: flex;
}
.detalhar-lote .form-control {
border:none;
font-weight: 300;
color: #757575;
}
.detalhar-lote  input[type="date"]  {
border-radius: 8px;
}
.calendario {
  padding: 10px 5px 10px 0;
  width: 50%;
}
.calendario-final {
  padding: 10px 0 10px 5px;
  width: 50%;
}
.calendario-botao {
float: left;
}
#atualizar {
border-radius: 8px;
cursor: pointer;
border:none;
margin-top: 24px;
width: 100%;
text-transform: uppercase;
background: #3E3E3E;
font-weight: 300;
}
#atualizar:hover {
background: #a1cd7b;
border:none;
}

.grafico h2 {
color: #3E3E3E;
font-size: 14px;
text-transform: uppercase;
}
.detalhar-dados {
padding:0px;
margin-top: 30px;
}
.detalhar-dados p {
color: #6B6C6C;
font-weight: 300;
font-size: 16px;
}
.detalhar-dados div:nth-child(1) {width: 8.7%; margin-right: 1%;}
.detalhar-dados div:nth-child(2) {width: 10%; margin-right: 1%;}
.detalhar-dados div:nth-child(3) {width: 13%; margin-right: 1%; cursor: pointer;}
.detalhar-dados div:nth-child(4) {width: 9%; margin-right: 1%; cursor: pointer;}
.detalhar-dados div:nth-child(5) {width: 10%; margin-right: 1%; cursor: pointer;}
.detalhar-dados div:nth-child(6) {width: 10%; margin-right: 1%; cursor: pointer;}
.detalhar-dados div:nth-child(7) {width: 11%; cursor: pointer;}

.detalhar-dados div:nth-child(3):hover,
.detalhar-dados div:nth-child(4):hover,
.detalhar-dados div:nth-child(5):hover,
.detalhar-dados div:nth-child(6):hover,
.detalhar-dados div:nth-child(7):hover
{
background: #fbfbfb;
}

.detalhar-dados div {
background: #FFF;
border-radius: 8px;
float: left;
padding: 12px;
}
.clear {
clear: both;
}
.alert {
border-radius: 8px;
padding: 10px 10px;
margin: 20px 0 -20px 0;
text-align: center;
}
.alert p {
margin: 0;
}

.alert-success {
    display: none;
    clear: both;
    margin: 20px 0 0 0;
    padding: 10px;
    text-align: center;
    border: solid 2px #78B843;
    background: #E9F4DF;
    border-radius: 8px;
}

.card-body-icon {
display: none;
}
.detalhar-dados p {
margin: 0 0 5px 0;
}
.detalhar-dados span {
color: #78B843;
font-weight: 700;
font-size: 20px;
}
.detalhar-dados .valor-atual-acima {color: #415430;}
.detalhar-dados .valor-atual-bom {color: #78B843;}
.detalhar-dados .valor-atual-medio {color: #e2bf65;}
.detalhar-dados .valor-atual-ruim {color: #a94949;}

.detalhar-dados .weight {
color: blue;
}
.detalhar-dados .valor-estimado {
color: white;
font-size: 16px;
font-weight: 400;
}
.detalhar-dados {
  margin-left: 40px;
  margin-right: -26%;
}

.smartmic {
margin: 30px 0 0 0;
}
.smartmic h2 {
font-size: 14px;
}
.smartmic-total div {
background: #FFF;
border-radius: 8px;
float: left;
padding: 12px 20px;
margin-right: 1%;
}
.smartmic-total p {
color: #6B6C6C;
font-weight: 300;
font-size: 16px;
margin: 0 0 5px 0;
}
.smartmic-total span {
color: #3E3E3E;
font-weight: 700;
font-size: 20px;
}
.tosse-atual-bom {background: #78B843 !important;}
.tosse-atual-medio {background: #e2bf65 !important;}
.tosse-atual-ruim {background: #a94949 !important;}
.tosse-atual-neutral {background: #b6b6b6 !important;}

.tosse-atual-bom p, .tosse-atual-bom span,
.tosse-atual-medio p, .tosse-atual-medio span,
.tosse-atual-ruim p, .tosse-atual-ruim span,
.tosse-atual-neutral p, .tosse-atual-neutral span
{color: #FFF;}

.situacao-atual-bom span {color: #78B843 !important;}
.situacao-atual-medio span {color: #e2bf65 !important;}
.situacao-atual-ruim span {color: #a94949 !important;}
.situacao-atual-neutral span {color: #b6b6b6 !important;}

.entrada-barracao {
background: #F2F4F6;
float: left;
padding: 94px 0;
font-size: 14px;
font-weight: 500;
margin: 24px 2% 0 0;
width: 10%;
text-align: center;
}
.smartmic-sensor-tosse {
width: fit-content;
display: inline-table;
width: 10%;
margin-right: 1%;
display: block;
float: left;
}
.smartmic-sensor-tosse span {
width: 48%;
height: 80px;
float: left;
margin-right: 2%;
}
.smartmic-sensor-tosse p {
margin: 0 auto;
text-align: center;
}
.smartmic .sensor0 span {
background: #F2F4F6;
}
.smartmic .sensor1 span {
background: #DFDFDF;
}
.bloco-info-sensor {
clear: both;
padding: 5px 0 0 0;
}
.smartmic a {
color: #3E3E3E;
text-decoration: underline;
display: block;
margin-top: 7px;
}
.sensor-tosse-bom {
width: 20px;
height: 20px;
border: none;
border-radius: 50%;
background: #78B843;
box-shadow: 0 0 0 0 rgba(120, 184, 67, 0.5);
-webkit-animation: pulse-bom 1.5s infinite;
}
.sensor-tosse-medio {
width: 20px;
height: 20px;
border: none;
border-radius: 50%;
background: #D6A424;
box-shadow: 0 0 0 0 rgba(214, 164, 36, 0.5);
-webkit-animation: pulse-medio 1.5s infinite;
}
.sensor-tosse-ruim {
width: 20px;
height: 20px;
border: none;
border-radius: 50%;
background: #A94949;
box-shadow: 0 0 0 0 rgba(169, 73, 73, 0.5);
-webkit-animation: pulse-ruim 1.5s infinite;
}

.sensor-tosse-disable {
width: 20px;
height: 20px;
border: none;
border-radius: 50%;
background: #dcd9d9;
box-shadow: 0 0 0 0 #dadada;
-webkit-animation: pulse-ruim 1.5s infinite;
}

@-webkit-keyframes pulse-bom {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(120, 184, 67, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(120, 184, 67, 0);
  }
}

@-webkit-keyframes pulse-medio {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(214, 164, 36, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(214, 164, 36, 0);
  }
}

@-webkit-keyframes pulse-ruim {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(169, 73, 73, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(169, 73, 73, 0);
  }
}

.rTable6 {width: 100%; font-weight: 300;}
.rTable6 thead {font-weight: bold; color:#3E3E3E; font-size:14px; }
.rTable6 tbody {background: #F4F4F4;}
.rTable6 tr {border-bottom: solid 10px #FFF;}
.rTable6 tbody span {display: none;}
.rTable6 tbody p {margin: 0;}

.rTable6 tr th:nth-child(2) {text-align: right;}
.rTable6 tr th:nth-child(3),
.rTable6 tr th:nth-child(4),
.rTable6 tr th:nth-child(5),
.rTable6 tr th:nth-child(6),
.rTable6 tr th:nth-child(7),
.rTable6 tr th:nth-child(8),
.rTable6 tr th:nth-child(9),
.rTable6 tr th:nth-child(10)
{text-align: center;}

.rTable6 tbody tr td:nth-child(1){width:23%; padding: 10px;}
.rTable6 tbody tr td:nth-child(2){width:13%; padding: 10px; background: #575757; color: #FFF; font-weight:500; text-align: center;}
.rTable6 tbody tr td:nth-child(3){width:8%; padding: 10px; text-align: center; border-right: solid 3px #f4f4f4;}
.rTable6 tbody tr td:nth-child(4){width:8%; padding: 10px; text-align: center; border-right: solid 3px #f4f4f4;}
.rTable6 tbody tr td:nth-child(5){width:8%; padding: 10px; text-align: center; border-right: solid 3px #f4f4f4;}
.rTable6 tbody tr td:nth-child(6){width:8%; padding: 10px; text-align: center; border-right: solid 3px #f4f4f4;}
.rTable6 tbody tr td:nth-child(7){width:8%; padding: 10px; text-align: center; border-right: solid 3px #f4f4f4;}
.rTable6 tbody tr td:nth-child(8){width:8%; padding: 10px; text-align: center; border-right: solid 3px #f4f4f4;}
.rTable6 tbody tr td:nth-child(9){width:8%; padding: 10px; text-align: center; border-right: solid 3px #f4f4f4;}
.rTable6 tbody tr td:nth-child(10){width:8%; text-align: center; border-right: solid 3px #f4f4f4;}


@media screen and (max-width: 1199px) {
.detalhar-dados div:nth-child(1) {width: 32.66%; margin-bottom: 10px; margin-right: 1%;}
.detalhar-dados div:nth-child(2) {width: 32.66%; margin-bottom: 10px; margin-right: 1%;}
.detalhar-dados div:nth-child(3) {width: 32.66%; margin-bottom: 10px; margin-right: 0;}
.detalhar-dados div:nth-child(4) {width: 32.66%; margin-bottom: 10px; margin-right: 1%;}
.detalhar-dados div:nth-child(5) {width: 32.66%; margin-bottom: 10px; margin-right: 1%;}
.detalhar-dados div:nth-child(6) {width: 32.66%; margin-bottom: 10px; margin-right: 0%;}
.detalhar-dados div:nth-child(7) {width: 32.66%; margin-bottom: 10px;}

.progress-bar-vertical {min-height: 246px;}

  .detalhar-dados {
    margin-left: 0px;
    margin-right: 0%;
  }
}

@media screen and (max-width: 991px) {
.tab-lote-detalhado {
  margin-bottom: 20px;
}

.nav-link { padding: .5rem 1.18rem; }

.rTable thead, .rTable2 thead, .rTable4 thead, .rTable5 thead, .rTable6 thead  {display:none;}
.rTable tbody td, .rTable2 tbody td, .rTable3 tbody td, .rTable4 tbody td, .rTable5 tbody td, .rTable6 tbody td {display: flex; flex-direction: column;}
.rTable .sensores, .rTable3 .sensores {display: flex; flex-direction: row; }
.rTable .botoes, .rTable3 .botoes {display: flex; flex-direction: row; }
.rTable th, .rTable td, .rTable2 th, .rTable2 td{border-bottom: dotted 1px #DADADA;}
.rTable tr, .rTable2 tr {border-bottom: solid 20px #FFF;}
.rTable tbody tr td:nth-child(1), .rTable tbody tr td:nth-child(2), .rTable tbody tr td:nth-child(3), .rTable tbody tr td:nth-child(4), .rTable tbody tr td:nth-child(5), .rTable tbody tr td:nth-child(6), .rTable tbody tr td:nth-child(7) {width:100%;}
.rTable .galpao-vazio td:nth-child(3), .rTable .galpao-vazio td:nth-child(4), .rTable .galpao-vazio td:nth-child(5), .rTable .galpao-vazio td:nth-child(6) {display: none;}

.rTable5 tbody tr td:nth-child(1), .rTable5 tbody tr td:nth-child(2), .rTable5 tbody tr td:nth-child(3), .rTable5 tbody tr td:nth-child(4), .rTable5 tbody tr td:nth-child(5), .rTable5 tbody tr td:nth-child(6) {width:100%;}
.rTable5 .botoes a:last-child {background: #dcd9d9;}
.rTable5 .botoes a:last-child {border-right: none}

.rTable .botoes, .rTable5 .botoes {display: flex; flex-direction: row; }
.rTable td:nth-child(5) img, .rTable3 td:nth-child(4) img {width: 25px;}

.rTable6 tbody tr td:nth-child(1), .rTable6 tbody tr td:nth-child(2), .rTable6 tbody tr td:nth-child(3), .rTable6 tbody tr td:nth-child(4), .rTable6 tbody tr td:nth-child(5), .rTable6 tbody tr td:nth-child(6), .rTable6 tbody tr td:nth-child(7), .rTable6 tbody tr td:nth-child(8), .rTable6 tbody tr td:nth-child(9), .rTable6 tbody tr td:nth-child(10) {width:100%;}
.rTable6 tbody tr td {display: contents; border-bottom: solid 3px #F4F4F4;}
.rTable6 tbody tr td:nth-child(1) p {float: left; padding: 10px; background: #dfdfdf; width: 25%;}
.rTable6 tbody tr td:nth-child(2) p {background: #575757; padding: 10px; width: 75%; margin-left: 25%;}
.rTable6 tbody tr td span {display:block; font-weight: 600; float: left; padding:0 10px 0 10px;}
.rTable6 tbody tr td p {text-align: left; margin: 0;}

.rTable6 tbody tr td:nth-child(3) p,
.rTable6 tbody tr td:nth-child(4) p,
.rTable6 tbody tr td:nth-child(5) p,
.rTable6 tbody tr td:nth-child(6) p,
.rTable6 tbody tr td:nth-child(7) p,
.rTable6 tbody tr td:nth-child(8) p,
.rTable6 tbody tr td:nth-child(9) p,
.rTable6 tbody tr td:nth-child(10) p
{width: 9%; float: left; padding: 10px 0; text-align: left;}

.rTable6 tbody tr td:nth-child(3) span,
.rTable6 tbody tr td:nth-child(4) span,
.rTable6 tbody tr td:nth-child(5) span,
.rTable6 tbody tr td:nth-child(6) span,
.rTable6 tbody tr td:nth-child(7) span,
.rTable6 tbody tr td:nth-child(8) span,
.rTable6 tbody tr td:nth-child(9) span,
.rTable6 tbody tr td:nth-child(10) span
{width: 16%; float: left; padding: 10px 0 10px 10px; text-align: left;}

.detalhar-lote {display:inline-block; width: 100%;}
.detalhar-lote-botoes {flex: none; display: inline-block; margin-bottom: 10px;}
.calendario {width: 100%; padding: 10px 5px 0 0;}
.calendario-final {width: 100%; padding: 10px 0 0 5px;}

.progress-bar-vertical {min-height: 246px;}

.smartmic-total div:nth-child(1), .smartmic-total div:nth-child(2), .smartmic-total div:nth-child(3), .smartmic-total div:nth-child(4), .smartmic-total div:nth-child(5), .smartmic-total div:nth-child(6), .smartmic-total div:nth-child(7) {width: 32.3% !important; margin-bottom: 5px;}
}

@media screen and (max-width: 768px) {
  .nav-item a img {
    width: 24px;
  }

  .nav-item a p {
    display: none;
  }

.detalhar-dados div:nth-child(1) {width: 49.5%; margin-bottom: 10px; margin-right: 1%;}
.detalhar-dados div:nth-child(2) {width: 49.5%; margin-bottom: 10px; margin-right: 0;}
.detalhar-dados div:nth-child(3) {width: 49.5%; margin-bottom: 10px; margin-right: 1%;}
.detalhar-dados div:nth-child(4) {width: 49.5%; margin-bottom: 10px; margin-right: 0;}
.detalhar-dados div:nth-child(5) {width: 49.5%; margin-bottom: 10px; margin-right: 1%;}
.detalhar-dados div:nth-child(6) {width: 49.5%; margin-bottom: 10px; margin-right: 0;}
.detalhar-dados div:nth-child(7) {width: 49.5%; margin-bottom: 10px; margin-right: 0;}
.detalhar-lote {display:inline-block; width: 100%;}
.calendario {width: 50%; padding: 10px 5px 10px 0px;}
.calendario-final {width: 50%; padding: 10px 0px 10px 5px;}

.filtrar-sensor-bl:nth-child(1) {width: 50%; padding-right: 10px;}
.filtrar-sensor-bl:nth-child(2) {width: 50%; padding-right: 0px;}
.filtrar-sensor-bl:nth-child(3) {width: 100%; margin-bottom: 30px;}

.smartmic-total div {padding: 10px 8px;}

.smartmic-sensor-tosse {width: 11.25%; margin-right: 0.5%;}
.smartmic-sensor-tosse span {height: 40px; width: 47%; margin-right: 3%;}

.progress-bar-vertical {min-height: 246px;}

.rTable6 tbody tr td:nth-child(3) p,
.rTable6 tbody tr td:nth-child(4) p,
.rTable6 tbody tr td:nth-child(5) p,
.rTable6 tbody tr td:nth-child(6) p,
.rTable6 tbody tr td:nth-child(7) p,
.rTable6 tbody tr td:nth-child(8) p,
.rTable6 tbody tr td:nth-child(9) p,
.rTable6 tbody tr td:nth-child(10) p
{width: 11%; float: left; padding: 10px 0; text-align: left;}

.rTable6 tbody tr td:nth-child(3) span,
.rTable6 tbody tr td:nth-child(4) span,
.rTable6 tbody tr td:nth-child(5) span,
.rTable6 tbody tr td:nth-child(6) span,
.rTable6 tbody tr td:nth-child(7) span,
.rTable6 tbody tr td:nth-child(8) span,
.rTable6 tbody tr td:nth-child(9) span,
.rTable6 tbody tr td:nth-child(10) span
{width: 22%; float: left; padding: 10px 0 10px 10px; text-align: left;}
}

@media screen and (max-width: 519px) {
.rTable6 tbody tr td:nth-child(3) p,
.rTable6 tbody tr td:nth-child(4) p,
.rTable6 tbody tr td:nth-child(5) p,
.rTable6 tbody tr td:nth-child(6) p,
.rTable6 tbody tr td:nth-child(7) p,
.rTable6 tbody tr td:nth-child(8) p,
.rTable6 tbody tr td:nth-child(9) p,
.rTable6 tbody tr td:nth-child(10) p
{width: 14%; float: left; padding: 10px 0; text-align: left;}

.rTable6 tbody tr td:nth-child(3) span,
.rTable6 tbody tr td:nth-child(4) span,
.rTable6 tbody tr td:nth-child(5) span,
.rTable6 tbody tr td:nth-child(6) span,
.rTable6 tbody tr td:nth-child(7) span,
.rTable6 tbody tr td:nth-child(8) span,
.rTable6 tbody tr td:nth-child(9) span,
.rTable6 tbody tr td:nth-child(10) span
{width: 34%; float: left; padding: 10px 0 10px 10px; text-align: left;}

.rTable6 tbody tr td:nth-child(1) p {width: 40%;}
.rTable6 tbody tr td:nth-child(2) p {width: 60%; margin-left: 40%;}
}

@media screen and (max-width: 480px) {
  .calendario {width: 100%; padding: 10px 0;}
  .calendario-final {width: 100%; padding: 10px 0;}
  .calendario-botao {margin-top: -10px; width: 100%;}
}

@media screen and (max-width: 465px) {
.detalhar-lote-botoes a {padding: 8px 5px; font-size: 13px;}
.nav-tabs {margin-top: 5px; display: flex; justify-content: center;}
.detalhar-lote-botoes {width: 100%;}
.detalhar-lote-botoes a {margin-top: 10px; font-size: 18px; width: 100%; float: none;}
.edit-batch-button {padding: 0;}
#atualizar {margin-top: 20px; font-size: 18px; width: 100%; float: none;}
.smartfeed-info div:nth-child(1) {width: 50%;}
.smartfeed-info div:nth-child(2) {width: 49%; margin-right: 0;}
.smartfeed-info div:nth-child(3) {width: 100%; margin-top: 5px;}
.rTable6 tbody tr td:nth-child(3) p, .rTable6 tbody tr td:nth-child(4) p, .rTable6 tbody tr td:nth-child(5) p, .rTable6 tbody tr td:nth-child(6) p, .rTable6 tbody tr td:nth-child(7) p, .rTable6 tbody tr td:nth-child(8) p, .rTable6 tbody tr td:nth-child(9) p, .rTable6 tbody tr td:nth-child(10) p {width: 45px;}
}
</style>
