<template>
  <div
    class="modal fade"
    id="cadastrarusuarioModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="cadastrarusuarioModal"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cadastrarusuarioModal">
            <i class="fas fa-user"></i> CADASTRAR USUÁRIO
          </h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          <form method="post" action="">
            <input
              type="text"
              name="Nome"
              class="form-control"
              placeholder="Nome"
            />
            <input
              type="text"
              name="Sobrenome"
              class="form-control"
              placeholder="Sobrenome"
            />
            <input
              type="email"
              name="email"
              class="form-control"
              placeholder="email"
            />
            <input
              type="text"
              name="Nome-de-usuario"
              class="form-control"
              placeholder="Nome de usuário"
            />
            <input
              type="password"
              name="password"
              class="form-control"
              placeholder="Senha"
            />
            <input
              type="password"
              name="password"
              class="form-control"
              placeholder="Repetir senha"
            />
            <button type="submit" class="modal-btn">Cadastrar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterUserModal",
};
</script>

<style scoped>
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    padding-left: 10px;
}

select {
    margin: 0px;
    margin-top: 5px;
    padding-left: 0px;
}

.modal-body select {
    padding-left: 6px;
}
</style>
