<template>
  <div class="container-fluid internas-header">
    <div class="container">
      <div class="navbar">
        <div class="logo">
          <router-link to="/home-page">
            <img style="padding:12px" src="../assets/logo.svg" />
          </router-link>
        </div>

        <div class="icos-header">
          <router-link to="/home-page">
            <img src="../assets/ico-home.svg"/>
          </router-link>

          <a href="#" data-toggle="modal" data-target="#logoutModal">
            <img src="../assets/ico-log-out.svg" />
          </a>
        </div>
      </div>
    </div>

    <ModalStyle>
      <LogoutModal />
      <RegisterUserModal />
    </ModalStyle>
  </div>
</template>

<script>
import ModalStyle from "./ModalStyle.vue";
import LogoutModal from "./LogoutModal.vue";
import RegisterUserModal from "./RegisterUserModal.vue";

export default {
  name: "Navbar",
  components: {
    ModalStyle,
    LogoutModal,
    RegisterUserModal
  },
};

</script>

<style scoped>
.container{
  width: 100%;
  display: flex;
  justify-content: center;
}

.internas-header {
  display: flex;
  justify-content: center;
  background: #6ca63c;
  padding: 10px 0 10px 0;
}

.logo {
  margin-bottom: 0;
  padding: 1px 0 0 1px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
}

.logo img {
  width: 180px;
}

.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
}

.icos-header {
  display: flex;
  justify-content: center;
  text-align: center;
}

.icos-header a {
  cursor: default;
  padding: 0 8px;
}

.icos-header img {
  cursor: pointer;
}

.infos-header .dropdown-menu {
  border: none;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.22);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.22);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.22);
}

.infos-header .dropdown-item, .internas-header .dropdown-item {
  padding: 10px 25px 10px 20px;
  border-bottom: dotted 1px #CCC;
  font-weight: 400;
  color: #3E3E3E;
}

.infos-header .dropdown-item:last-child, .internas-header .dropdown-item:last-child {
  border-bottom: none;
}

@media screen and (max-width: 360px) {
  .logo img {
    width: 156px;
  }
}

</style>
