<template>
  <div
    class="modal fade"
    id="logoutModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Deseja realmente sair do sistema?
          </h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          Selecione "Sair" para encerrar sua sessão.
        </div>

        <div class="modal-footer">
          <button class="modal-btn2" type="button" data-dismiss="modal">
            Cancelar
          </button>
          <a class="modal-btn" data-dismiss="modal" v-on:click="logout">Sair</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoutModal",
  methods: {
    logout: function() {
      this.$store.dispatch("logout")
      this.$router.push("/")
      window.localStorage.clear()
    }
  }
};

</script>

<style scoped>
.modal-header {
  background: #78b843;
  color: #fff;
  padding: 25px 20px 20px 10px;
}

#baixaModal img {
  width: 40px;
  margin: -3px 5px 0 0;
}

#pendenciaModal img {
  width: 30px;
  margin: -5px 10px 0 0;
}

.modal-content {
  border-bottom: solid 5px #78b843;
  font-size: 17px;
  color: #3e3e3e;
  font-weight: 300;
}

.modal-btn {
  color: #fff;
  background-color: #78b843;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
}

.modal-btn:hover {
  background-color: #1b4238;
  color: #fff;
  text-decoration: none;
}

.modal-btn2 {
  color: #fff;
  background-color: #1b4238;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
}

.modal-btn2:hover {
  background-color: #78b843;
  color: #fff;
  text-decoration: none;
}

.modal-content .form-label-group {
  margin-bottom: 10px;
}

.modal-content input,
.modal-content textarea {
  border: none;
  background: #f2f4f6;
  border-radius: 8px;
}

#inputObservacoes {
  width: 100%;
  margin-top: 10px;
  font-size: 17px;
  color: #3e3e3e;
  font-weight: 300;
  height: 100px;
  padding: 10px;
}

.editar-lote-modal img {
  padding: 0px 10px 0 0;
  margin: -3px 0 0 0;
}

.modal-content label {
  margin: 10px 0 0 0;
}

.modal-footer {
  border: none;
}

</style>
