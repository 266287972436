<template>
  <!-- Cadastrar Baixa Modal-->
  <div
    class="modal fade"
    id="registerWeightModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <img src="../assets/ico-edit.svg" /> CADASTRAR PESO MANUAL
          </h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="registerWeight(batch, weight, datetime_registered)">
            <label>Barracão: {{barnName}}</label><br />
            
            <label>Peso Manual: </label><br />
            <input
              type="number"
              class="form-control"
              placeholder="Peso em kg"
              v-model="weight"
              step="0.01"
              required
            />

            <label>Data e Hora</label><br />
            <input
              type="datetime-local"
              class="form-control"
              v-model="datetime_registered"
              required
            />
            <button type="submit" class="modal-btn">Cadastrar</button>
          </form>
        </div>
        <!-- end modal body -->
      </div>
    </div>
    <SuccessAlert ref="successAlert" v-bind:message="messageSuccess"/>
    <ErrorAlert ref="errorAlert" v-bind:message="messageFailure"/>
  </div>
  <!-- end Cadastrar Baixa  -->
</template>

<script>
import ErrorAlert from "./ErrorAlert.vue";
import SuccessAlert from "./SuccessAlert.vue";

export default {
  name: "LossModal",
  components: {
    ErrorAlert,
    SuccessAlert
  },
  data() {
    return {
      weight: null,
      datetime_registered: null,
      messageSuccess: "peso cadastrado",
      messageFailure: "não foi possível registrar o peso"
    }
  },
  methods: {
    async registerWeight(batch, weight, datetime_registered) {
      await this.$store.dispatch("registerWeight", {batch, weight, datetime_registered})
      if (!(this.$store.getters.getError)) {
        this.$refs.errorAlert.closeAlert()
        this.$refs.successAlert.closeAlert()
        this.$refs.successAlert.alert()
      }
      else {
        this.$refs.errorAlert.closeAlert()
        this.$refs.successAlert.closeAlert()
        this.$refs.errorAlert.alert()
      }
    }
  },
  computed: {
    barnName() {
      if (this.barn == null) return ""
      return `${this.barn.name}`
    },
    barn() {
      return this.$store.getters.getBarnTarget
    },
    batch() {
      return this.$store.getters.getBatchTarget
    },
  }
}
</script>

<style scoped>
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  padding-left: 10px;
}

select {
  margin: 0px;
  margin-top: 5px;
  padding-left: 0px;
}

.modal-body select {
  padding-left: 6px;
}

.modal-body label {
  padding-left: 12px;
}
</style>
