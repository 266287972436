<template>
  <div id="app" >
    <Navbar v-if="activeNavbar"/>
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  components: {
    Navbar
  },
  computed: {
    activeNavbar() {
      return this.$store.getters.getNavbar;
    }
  }
}
</script>

<style>
body {
  background: #f2f2f2;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

:root {
  --light-green: #78b843;
}

.conteudo {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 40px;
}
</style>
