<template>
  <div class="login">
    <div class="login-header">
      <img src="../assets/logo.svg" />
    </div>

    <p class="login-icone">
      <img src="../assets/recuperar-senha.png" />
    </p>

    <form action="" method="post">
      <input type="email" name="email" placeholder="Email" />
      <button type="submit">Enviar link de recuperação de senha</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "RecoverPasswordPage",
};

</script>

<style scoped>
.login {
  background: #ffffff;
  border-radius: 8px;
  margin: 100px auto 0 auto;
}

.login-header {
  background: #78b843;
  background-image: url("../assets/login-fundo.png");
  background-size: cover;
  border-radius: 8px 8px 0 0;
  padding: 40px 0;
}

.login-header img {
  display: block;
  margin: 0 auto;
  padding: 20px;
  width: 290px;
}

.login-icone {
  padding: 0 20px;
  margin-top: -50px;
}

.login form {
  padding: 20px 0 30px 20px;
}

.login input[type="email"] {
  background: #f2f4f6;
  border: solid 1px #eaeaea;
  padding: 15px;
  margin-bottom: 10px;
  width: 95%;
  font-size: 16px;
  color: #656464;
}

.login form button {
  background: #78b843;
  color: #fff;
  border: none;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 20px 12px 20px;
  border-radius: 2px;
  margin-top: 20px;
  cursor: pointer;
}

.login form button:hover {
  background: #1b4238;
}

@media screen and (max-width: 1199px) {
  .login {
    width: 45%;
  }
}

@media screen and (min-width: 1201px) {
  .login {
    width: 40%;
  }
}

@media screen and (max-width: 991px) {
  .login {
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .login {
    width: 80%;
  }
}

@media screen and (max-width: 576px) {
  .login {
    width: 95%;
  }
}

</style>
