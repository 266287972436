<template>
  <div class="modal-position bg-animation">
    <transition name="modal modal-mask">
      <div class="modal-dialog">
        <div
          class="modal-content modal-container modal-animation"
          style="top: 20vh;"
        >
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <img class="register-icon" src="../assets/ico-edit.svg" />
              CADASTRAR BARRACÃO
            </h5>
            <button
              class="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              @click="$emit('close')"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <form @submit.prevent="registerBarn(name, selectedFarm)">
              <label>Selecionar Granja</label><br />
              <select class="form-control" v-model="selectedFarm" required>
                <option v-bind:key="farm.id" v-for="farm in allFarms">{{farm.name}}</option>
              </select>

              <label>Nome do Barracão</label><br />
              <input
                type="text"
                class="form-control"
                placeholder="Nome do Barracão"
                v-model="name"
                required
              />

              <button type="submit" :class="button">Cadastrar</button>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <SuccessAlert ref="successAlert" v-bind:message="messageSuccess" />
    <ErrorAlert ref="errorAlert" v-bind:message="messageFailure" />
  </div>
</template>

<script>
import ErrorAlert from "./ErrorAlert.vue";
import SuccessAlert from "./SuccessAlert.vue";

export default {
  name: "RegisterBarnAltModal",
  props: ["allFarms"],
  components: {
    ErrorAlert,
    SuccessAlert,
  },
  data() {
    return {
      name: "",
      messageSuccess: "Barracão cadastrado",
      messageFailure: "Não foi possível registrar o Barracão",
      button: "button",
      selectedFarm: ""
    };
  },
  methods: {
    async registerBarn(name, selectedFarm) {
      if (this.button === "button-disable") return;

      document.body.style.cursor = "progress";
      this.button = "button-disable";

      const farm_pk = this.allFarms.find(item => item.name === selectedFarm).farm;
      const response = await this.$store.dispatch("registerBarn", {
        name,
        farm_pk,
      });

      if (response) {
        this.$refs.errorAlert.closeAlert();
        this.$refs.successAlert.closeAlert();
        this.$refs.successAlert.alert();
      } else {
        this.$refs.errorAlert.closeAlert();
        this.$refs.successAlert.closeAlert();
        this.$refs.errorAlert.alert();
      }

      document.body.style.cursor = "default";
      this.button = "button";
    },
  },
};
</script>

<style scoped>
.modal-animation {
  animation: down 0.3s ease;
}

@keyframes down {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}

.bg-animation {
  animation: appear 0.3s ease;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-position {
  z-index: 9998;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  padding-left: 10px;
}

select {
  margin: 0px;
  margin-top: 5px;
  padding-left: 0px;
}

.modal-body select {
  padding-left: 6px;
}

.modal-body label {
  padding-left: 12px;
}

.register-icon {
  margin-bottom: 4.5px;
}

.button {
  color: #fff;
  background-color: #78b843;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
}

.button:hover {
  background-color: #1b4238;
  color: #fff;
  text-decoration: none;
}

.button-disable {
  color: #fff;
  background-color: #999999;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
  cursor: not-allowed;
  outline: none;
}
</style>
