<template>
  <div>
    <a class="card-button" @click="changeBarnTarget(barn, batch, sensors, '/batch-details/smart-mic')">
      <div title="Média de Tosses de ontem " v-bind:class="smartMicColor">
        <div
          class="progress-bar "
          role="progressbar"
          style="width: 100%; padding: 0; margin: 0 2px; border-radius: 4px"
        > 
          <AnimadePlaceHolder v-if="dataMic.length == 0" height="40px" width="100%" borderRadius="4px"/>
          <div class="container-information-td" v-else>
            <img class="imgIcon" src="../assets/mic-white.svg" />
            <p class="product-information" >
                {{coughsCount}}
            </p>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { URL } from "../store/index"
import AnimadePlaceHolder from './AnimadePlaceHolder.vue'
export default {
  name: "SensorsMic",
  components: {
    AnimadePlaceHolder
  },
  data() {
    return {
        loading: true
    }
  },
  props: ["sensors", "indexBarn", 'barn', 'batch'],
  methods: {
    async getDataMic() {
      for (const element of this.sensors) {
        try {
          this.$store.commit('incrementActiveRequests');
          let url_to_fetch = `${URL}sensors/report/data/home/smartmic/smartmic_pk=${element.pk}`;
          const response = await fetch(url_to_fetch, {
              headers: this.$store.getters.getHeader
          });
          const json = await response.json();

          const allDataMic = {
            batch: this.batch[0],
            sensor: this.sensors,
            data: [json]
          }
          this.$store.commit('setSensorsListMic', allDataMic)
        } finally {
          this.$store.commit('decrementActiveRequests');
        }
      }
      this.loading = false
      this.$store.commit('updateStateByBarn', {pk: this.barn.pk, sensor: 'smartmic'})
    },
    changeBarnTarget(barn, batch, sensors, route) {
      if (this.dataMic.length > 0) {
        this.$store.commit("setBarnTarget", barn)
        this.$store.commit("setBatchTarget", batch[0] == undefined ? null : batch[0])
        this.$router.push(route)
      } 
    },
  },
  computed: {
    smartMicColor() {
      if (this.coughsCount === "SEM DADOS") return 'disable progress '
      if (this.coughsCount === "SEM LOTE") return 'disable progress '
      const sum = this.coughsCount/8

      if (sum >= 16) {
        return 'peso-atual-ruim progress '
      }
      if (sum >= 8)  {
        return 'peso-atual-medio progress '
      }
      if (sum < 8) {
        return 'peso-atual-bom progress '
      }
      return 'disable progress '
    },
    coughsCount() {
      if (this.batch[0].date_departure != null) return "SEM LOTE"
      if (this.dataMic.length > 0) {
        return this.dataMic[0].message !== undefined ? 'SEM DADOS' :this.dataMic[0].last_coughs_count
      }
      return 'SEM DADOS'
    },
    userRole() {
      return this.$store.getters.getCurrentUser.role
    },
    stateLoadingBarn(){
      return this.$store.getters.getStateBarns.find((barn)=>{
        return barn.pk == this.barn.pk
      })
    },
    getDataMicSave() {
      return this.$store.getters.getSensorsListMic
    },
    selectSensorMic() {
      return this.$store.getters.getSensorsListMic.find(obj => obj.batch.pk == this.batch[0].pk)
    },
    dataMic() {
      return this.selectSensorMic?.data ?? []
    },
  },
  
  async created() {
    if (this.dataMic.length == 0) {
      await this.getDataMic()
    }
  }
};
</script>

<style scoped>
.cursor{
  cursor: not-allowed;
}
.progress {
  border-radius: 0;
  height: 40px;
  background-color: #f4f4f4;
  font-weight: bold;
  font-size: 14px;
}

.progress-bar {
  text-align: left;
  padding-left: 10px;
}

.progress-bar span {
  color: #3e3e3e;
  background: url(../assets/listras.png) #fcfdfd;
  width: fit-content;
  padding: 0 10px;
  border-radius: 10px;
}

a {text-decoration: none;}
.peso-atual-acima .progress-bar {background: #415430;}
a .peso-atual-acima .progress-bar:hover {background: #273619;}
.peso-atual-bom .progress-bar {background: #a1cd7b;}
a .peso-atual-bom .progress-bar:hover {background: #5e7d42;}
.peso-atual-medio .progress-bar {background: #e2bf65;}
a .peso-atual-medio .progress-bar:hover {background: #c59e3a;}
.peso-atual-ruim .progress-bar {background: #a94949;}
a .peso-atual-ruim .progress-bar:hover {background: #952323;}
.no-sensor .progress-bar {background:  #f2f2f2; color: grey;}
a .no-sensor .progress-bar:hover {background: #cfcfcf;}
.disable .progress-bar {background:  #adadad;}
a .disable .progress-bar:hover {background: grey;}


.mic-data {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 

}

.mic-data:hover {
  animation: scaleAnimationMic 0.3s ease-in-out 25ms forwards;
}

@keyframes scaleAnimationMic {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);

  }
}

.container-information-td{
  display: flex;
  align-items: center;
  justify-content: center;

}

.imgIcon{
  width: 20px;
  margin: 4px;
  margin-right: 8px;

}

.feed-data {
  width: -moz-available;
  width: -webkit-fill-available;
}

.product-information {
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-size: 14px
}

.card-button {
  cursor: pointer;
}

@media screen and (min-width: 991px) {
  .imgIcon{
    display: none;
  }
}
</style>