<template>
  <tr>
    <td>
      <p class="info-galpoes-mobile">Granja:</p>
      {{batch.farm.name}}
    </td>
    <td>
      <p class="info-galpoes-mobile">Barracão:</p>
      {{batch.barn.name}}
    </td>
    <td>
      <p class="info-galpoes-mobile">Lote:</p>
      {{batch.pk}}
    </td>
    <td>
      <p class="info-galpoes-mobile">Início:</p>
      {{inicialDate}}
    </td>
    <td>
      <p class="info-galpoes-mobile">Fim:</p>
      {{finalDate}}
    </td>
    <td>
      <p class="info-galpoes-mobile">Animais:</p>
      {{batch.initial_count}}
    </td>
    <td v-if="hideBatchDetailTab">
      <router-link @click.native="changeBarnTarget(batch.barn, batch)" v-if="(batch !== null)" title="Visualizar" class="btn btn-success btn-circle" :to="{params: { barn: batch.barn, batch: batch }}"
        ><img src="../assets/eye.svg" alt="eye">
      </router-link>
    </td>
  </tr>
</template>

<script>
import { URL } from "../store/index"
import { parseISO, format } from 'date-fns';

export default {
  name: "OldBatch",
  data() {
    return {
      dataSensors: [],
      loadingSensor: true,
      loadingCam: true,
      loadingMic: true,
      loading: false
    }
  },
  props: ["batch", "hideBatchDetailTab"],
  methods: {
    async changeBarnTarget(barn, batch) {
      this.loading = true
      this.emitLoading(true)
      await this.sensorsFetch()
      this.$store.commit("setBarnTarget", barn)
      this.$store.commit("setBatchTarget", batch)
    },
    async sensorsFetch() {
      try {
        let url_to_fetch = `${URL}sensors/report/home/barn=${this.batch.barn.pk}/`;
        let response = await fetch(url_to_fetch, {
          headers: this.$store.getters.getHeader
        });
        this.dataSensors = await response.json();
        
        if (this.sensorsByType('smartcam').length > 0) {
          await this.getDataCam()
        } else {
          this.loadingCam = false
        }
        if (this.sensorsByType('smartmic', 'smartmic_central_V3').length > 0) {
          await this.getDataMic()
        } else {
          this.loadingMic = false
        }
      } catch (error) {
        return 
      }
      this.loadingSensor = false
    },
    async getDataCam() {
      try {
        let url_to_fetch = `${URL}sensors/report/data/home/smartcam/smartcam_pk=${this.sensorsByType('smartcam')[0].pk}`;
        const response = await fetch(url_to_fetch, {
            headers: this.$store.getters.getHeader
        });
        const json = await response.json();
        const allDataCam = {
          batch: this.batch,
          sensor: this.sensorsByType('smartcam'),
          data: [json],
        }
        this.$store.commit('setSensorsListCam', allDataCam)
      } finally { 
        this.loadingCam = false
      }
    },
    async getDataMic() {
      try {
        let url_to_fetch = `${URL}sensors/report/data/home/smartmic/smartmic_pk=${this.sensorsByType('smartmic', 'smartmic_central_V3')[0].pk}`;
        const response = await fetch(url_to_fetch, {
            headers: this.$store.getters.getHeader
        });
        const json = await response.json();

        const allDataMic = {
          batch: this.batch,
          sensor: this.sensorsByType('smartmic', 'smartmic_central_V3'),
          data: [json],
        }
        this.$store.commit('setSensorsListMic', allDataMic)
      } finally {
        this.loadingMic = false
      }
    },
    sensorsByType(type, type2) {
      if (this.dataSensors.length > 0) {
        if (type === 'smartmic') {
          return this.dataSensors.filter(sensor => sensor.type === type || sensor.type === type2)
        }
        return this.dataSensors.filter(sensor => sensor.type === type)
      }
      return []
    },
    emitLoading(value){
      if (value) this.$emit('activeLoading', value)
      this.$emit('desactiveLoading', value)
    }
  },
  computed: {
    finalDate() {
      if (this.batch.date_departure === null) {
        return "Lote ativo"
      }
      const dateParse = parseISO(this.batch.date_departure);
      return format(dateParse, 'dd-MM-yyyy');
    },
    inicialDate(){
      const dateParse = parseISO(this.batch.date_accomodation);
      return format(dateParse, 'dd-MM-yyyy');
    },
    dataDone() {
      return !this.loadingCam && !this.loadingMic && !this.loadingSensor
    },
  },
  watch:{
    dataDone(newVale) {
      if (newVale) {
        this.emitLoading(false)
        this.$router.push('/batch-details/batch-tab')
      }
    }
  }
};
</script>

<style scoped>
.btn {
  border-radius: 0;
}

.btn-success {
  background-color: #a1cd7b;
  border: none;
}

.btn-success:hover {
  background: #1e7e34;
}

.btn-disable {
  background-color: #bebebe;
  border: none;
}

.btn-disable:hover {
  background: #8b8b8b;
}

.btn-circle {
  width: 30px;
  height: 42px;
  padding: 0px 13px;
  font-size: 15px;
  line-height: 0;
  display: table-cell;
}


.rTable2 tr {
  border-bottom: solid 10px #fff;
}

.rTable2 tbody tr td:nth-child(1) {
  width: 22%;
  padding: 10px;
}
.rTable2 tbody tr td:nth-child(2) {
  width: 30%;
  padding: 10px;
}
.rTable2 tbody tr td:nth-child(3) {
  width: 8%;
  padding: 10px;
}
.rTable2 tbody tr td:nth-child(4) {
  width: 13%;
  padding: 10px;
}
.rTable2 tbody tr td:nth-child(5) {
  width: 13%;
  padding: 10px;
}
.rTable2 tbody tr td:nth-child(6) {
  width: 10%;
  padding: 10px;
}
.rTable2 tbody tr td:nth-child(7) {
  width: 5%;
}


@media screen and (min-width: 993px) {
  .info-galpoes-mobile {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .rTable2 thead,
  .rTable2 thead {
    display: none;
  }
  .rTable2 tbody td,
  .rTable2 tbody td {
    display: flex;
    flex-direction: column;
  }
  .rTable2 th,
  .rTable2 td {
    border-bottom: dotted 1px #dadada;
  }
  .rTable2 tr {
    border-bottom: solid 20px #fff;
  }
  .rTable2 tbody tr td:nth-child(1),
  .rTable2 tbody tr td:nth-child(2),
  .rTable2 tbody tr td:nth-child(3),
  .rTable2 tbody tr td:nth-child(4),
  .rTable2 tbody tr td:nth-child(5),
  .rTable2 tbody tr td:nth-child(6),
  .rTable2 tbody tr td:nth-child(7),
  .rTable2 tbody tr td:nth-child(8) {
    width: 100%;
  }
  .rTable2 tbody tr td:nth-child(1) {
    font-weight: 700;
    background: #404040;
    color: #fff;
    text-transform: uppercase;
  }
  .info-galpoes-mobile {
    display: contents;
    font-weight: 500;
  }
  .btn-success {
    color: #000;
    background-color: #a1cd7b;
    border: none;
  }
  .btn-circle {
    padding: 11px 13px;
    font-size: 22px;
    width: 100%;
  }
  .btn-success:hover {
    background-color:#1e7e34;
    color: #000;
  }
}

@media screen and (max-width: 767px) {
  .internas-header2 {
    padding: 5px 0 5px 0;
  }
  .internas-header2 h2 {
    margin-bottom: 18px;
    text-align: center;
    padding: 8px 0 5px 0;
    background: #6ba63c;
    border-radius: 50px;
  }
  .infos-header {
    text-align: center;
  }
}
</style>
