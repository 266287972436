<template>
  <div @click="slide()" class="chart-active">
    <div class="track" :class="trackColor">
      <div class="ball" :class="ballPosition">
        <div class="ball-style">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderButton",
  props: ["TrackType"],
  data() {
    return {
      ballPosition: "original",
      trackColor: this.TrackType,
    };
  },
  methods: {
    slide() {
      if (this.ballPosition === "original") {
        this.ballPosition = "accumulated"
        if (this.TrackType === "track-load") {
          this.trackColor = "track-load-accumulated"
        }
        else {
          this.trackColor = "track-unload-accumulated"
        }
      }
      else {
        this.ballPosition = "original"
        if (this.TrackType === "track-load") {
          this.trackColor = "track-load"
        }
        else {
          this.trackColor = "track-unload"
        }
      }
      this.$emit('changeChart')
    }
  },
};
</script>

<style scoped>
.chart-active {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  top: 0;
  left: 0;
  height: 42px;
}

.chart-active .chart-title {
  display: flex;
  align-items: center;
}

.chart-active .chart-title p {
  margin: 0;
}

.chart-active .track {
  height: 24px;
  width: 56px;
  border-radius: 24px;
}

.chart-active .track .ball {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  height: 20px;
  width: 20px;
  border-radius: 24px;
  background: rgb(211, 211, 211);
}

.chart-active .track .original {
  margin-left: 3px;
  animation-name: leftTransform;
  transition-timing-function: ease-in-out;
  animation-duration: 0.1s;
}

@keyframes leftTransform {
  0%   {margin-left: 33px;}
  100% {margin-left: 3px;}
}

.chart-active .track-unload {
  background: rgba(137, 194, 91);
  animation-name: trackUnload;
  animation-duration: 0.5s;
}

@keyframes trackUnload {
  0%   {background-color: rgba(91, 138, 52);}
  100% {background-color: rgba(137, 194, 91)}
}

.chart-active .track .accumulated {
  margin-left: 33px;
  animation-name: rightTransform;
  transition-timing-function: ease-in-out;
  animation-duration: 0.1s;
}

@keyframes rightTransform {
  0%   {margin-left: 3px;}
  100% {margin-left: 33px;}
}

.chart-active .track-unload-accumulated {
  background: rgba(91, 138, 52);
  animation-name: trackAccUnload;
  animation-duration: 0.5s;
}

@keyframes trackAccUnload {
  0% {background-color: rgba(137, 194, 91)}
  100%   {background-color: rgba(91, 138, 52);}
}

.chart-active .track-load {
  background: #999;
  animation-name: trackLoad;
  animation-duration: 0.5s;
}

@keyframes trackLoad {
  0%   {background-color: rgba(110, 110, 110);}
  100% {background-color: #999}
}

.chart-active .track-load-accumulated {
  background: rgba(110, 110, 110);
  animation-name: trackAccLoad;
  animation-duration: 0.5s;
}

@keyframes trackAccLoad {
  0% {background-color: #999}
  100%   {background-color: rgba(110, 110, 110);}
}

.ball-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 80%;
  width: 100%;
}

</style>
