<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="conteudo galpoes">
          <table class="rTable4">
            <thead>
              <tr>
                <th>NOME</th>
                <th>EMAIL</th>
                <th>RESPONSÁVEL POR</th>
                <th>CRIADO EM</th>
                <th>STATUS</th>
                <th>AÇÕES</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <p class="info-galpoes-mobile">Nome:</p>
                  Elizandra Mazuco
                </td>
                <td>
                  <p class="info-galpoes-mobile">Email:</p>
                  elizandrams@gmail.com
                </td>
                <td>
                  <p class="info-galpoes-mobile">Responsável por:</p>
                  Barracão 01 - Granja Nardelli
                </td>
                <td>
                  <p class="info-galpoes-mobile">Criado em:</p>
                  10/05/20
                </td>
                <td>
                  <p class="info-galpoes-mobile">Status:</p>
                  <label class="switch"
                    ><input type="checkbox" checked /><span
                      class="slider round"
                    ></span
                  ></label>
                </td>
                <td class="botoes">
                  <a
                    title="Editar Galpão"
                    class="btn btn-success btn-circle"
                    href="#"
                    data-toggle="modal"
                    data-target="#editargalpaoModal"
                    ><i class="fa fa-edit"></i
                  ></a>
                  <a
                    class="btn btn-success btn-circle"
                    href=""
                    data-toggle="modal"
                    data-target="#excluirgalpaoModal"
                  >
                    <i class="fa fa-trash-alt"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInformation",
};
</script>
