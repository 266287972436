<template>
  <div :id="chartContainerId" style="width: 90%; height: 90%;">
    <canvas :id="chartCanvasId" :width="widthCanvas" height="300"></canvas>
  </div>
</template>

<script>
import { Chart } from 'chart.js'

import moment from 'moment'

export default {
  name: "SiloProfileHistoryChart",
  props: ["silo_profiles", "width", "height"],
  computed:{
    currentWidthDevice() {
      return window.innerWidth
    },
    widthCanvas() {
      return this.currentWidthDevice < 700 ? 300 : 600
    },
  },
  methods: {
    renderChart() {
      var canvasContext = document.getElementById(this.chartCanvasId).getContext('2d');
      var canvasContainer = document.getElementById(this.chartContainerId);
      const dimensions = canvasContainer.getBoundingClientRect()
      canvasContext.canvas.width  = dimensions.width;
      canvasContext.canvas.height = dimensions.height;
      
      var ctx = document.getElementById(this.chartCanvasId);
      new Chart(ctx, {
        type: 'scatter',
        data: {
            datasets: [{
                label: 'Nivel do silo',
                data: this.silo_profiles.map(p => ({ x: new Date(p.datetime_fetched), y: p.level })),
                showLine: true
            }]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
                ticks: {
                    userCallback: function(label) {
                        return moment(label).format("DD/MM/YY - H:mm");
                    }
                }
              }],
            yAxes: [{
                ticks: {
                  min: Math.floor(Math.min(...this.silo_profiles.map(p => p.level)) / 10) * 10,
                  max: Math.ceil(Math.max(...this.silo_profiles.map(p => p.level)) / 10) * 10,
                }
              }],
          },
          layout:{
            padding: 20
          },
          elements: {
            point: {
              radius: 2,
              backgroundColor: '#89c25b',
              borderColor: '#89c25b',
              borderWidth: 1,
            }
          },
          tooltips: {
            custom: (tooltipModel) => {
              if (tooltipModel.dataPoints.length > 0) {
                const profile = this.silo_profiles.find(p => {
                  return new Date(p.datetime_fetched).getTime() === Number(tooltipModel.dataPoints[0].label)
                })
                this.$emit('profileHover', profile)
              }
            },
            intersect: false,
            mode: 'index',
            callbacks: {
              label: (tooltipItem) => {
                return `Nivel: ${tooltipItem.yLabel.toFixed(2)} | Data-hora: ${moment(tooltipItem.xLabel).format("DD/MM/YY - H:mm:ss")}`
              }
            },
            backgroundColor: '#fff',
            titleFontSize: 16,
            titleFontColor: '#89c25b',
            bodyFontColor: '#000',
            bodyFontSize: 14,
            displayColors: false,
            borderColor: '#89c25b',
            borderWidth: 2,
          },
          title: {
            display: true,
            text: 'Historico de nivel',
            fontSize: 20,
          },
          responsive: true,
          maintainAspectRatio: false,
          stroke: {
            curve: 'straight'
          },
        },
      });
    }
  },
  watch: {
    $props: {
      handler() {
        this.renderChart();
      },
      deep: true,
      immediate: true,
    },
  },
  beforeMount () {
    this.chartContainerId = `profile-history-canvas-container-${Math.random()}`
    this.chartCanvasId = `silo-profile-history-chart-${Math.random()}`
  },
  mounted () {
    this.renderChart()
  }
}
</script>
