<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ModalStyle",
};
</script>

<style>
.modal-header {
  background: #78b843;
  color: #fff;
  padding: 25px 20px 20px 10px;
}
#baixaModal img {
  width: 40px;
  margin: -3px 5px 0 0;
}
#pendenciaModal img {
  width: 30px;
  margin: -5px 10px 0 0;
}
.modal-content {
  border-bottom: solid 5px #78b843;
  font-size: 17px;
  color: #3e3e3e;
  font-weight: 300;
}
.modal-btn {
  color: #fff;
  background-color: #78b843;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
}
.modal-btn:hover {
  background-color: #1b4238;
  color: #fff;
  text-decoration: none;
}
.modal-btn2 {
  color: #fff;
  background-color: #1b4238;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
}
.modal-btn2:hover {
  background-color: #78b843;
  color: #fff;
  text-decoration: none;
}
.modal-content .form-label-group {
  margin-bottom: 10px;
}
.modal-content input,
.modal-content textarea {
  border: none;
  background: #f2f4f6;
  border-radius: 8px;
  margin-bottom: 5px;
}
#inputObservacoes {
  width: 100%;
  margin-top: 10px;
  font-size: 17px;
  color: #3e3e3e;
  font-weight: 300;
  height: 100px;
  padding: 10px;
}
.editar-lote-modal img {
  padding: 0px 10px 0 0;
  margin: -3px 0 0 0;
}
.modal-content label {
  margin: 10px 0 0 0;
}
.modal-footer {
  border: none;
}
</style>
