<template>
  <select name="Nome da Granja" v-model="option" @change="filter(option)">
    <option>Nome da Granja</option>
    <option>Nome do Barracão</option>
    <option>Data de Acomodação ▲</option>
    <option>Data de Acomodação ▼</option>
    <option>Data de Finalização ▲</option>
    <option>Data de Finalização ▼</option>
  </select>
</template>

<script>
export default {
  name: "HistorySort",
  data() {
    return {
      option: "Nome da Granja",
    };
  },
  methods: {
    filter: function(option) {
      if (option === "Nome da Granja") this.$emit("sortByFarmName");
      if (option === "Nome do Barracão") this.$emit("sortByBarnName");
      if (option === "Data de Acomodação ▼") this.$emit("sortByDateAccommodationIncreasing");
      if (option === "Data de Acomodação ▲") this.$emit("sortByDateAccommodationDecreasing");
      if (option === "Data de Finalização ▼") this.$emit("sortByDateDepartureIncreasing");
      if (option === "Data de Finalização ▲") this.$emit("sortByDateDepartureDecreasing");
    },
  },
};
</script>
