<template>
  <div id="smartmic" class="container">
    <div>
      <PageLoader v-if="loading"/>
    </div>
    <div class="warning" v-if="semDados">
      <br /><br />
      <div class="text">
        <h1 style="text-align: center">Sem dados disponiveis</h1>
      </div>
    </div>
    <div v-else>
      <div v-if="this.sensorsData.predictions != undefined && !loading">
        <LastDate :date="lastPing"/>
        <div class="smartmic">
          <h2>TOTAL</h2>

          <div class="smartmic-total" v-if="this.sensorsData.predictions != undefined">
            <!-- CLASSES: tosse-atual-bom | tosse-atual-medio | tosse-atual-ruim -->
            <LastFiveDaysSmartMicCard v-bind:sensorsData="sensorsData.predictions[index - 1]" v-bind:key="index" v-for="index in 5"/>

            <div :class="situationColor">
              <!-- CLASSES: situacao-atual-bom | situacao-atual-medio | situacao-atual-ruim -->
              <p>Situação Atual</p>
              <span>{{situationText}}</span>
              <!-- TEXTO: Estável | Alerta | Crítico -->
            </div>

            <div :class="trendColor">
              <p>Tendência</p>
              <span>{{coughTrend}}</span>
            </div>
          </div>
          <div class="clear"></div>
        </div>

        <div>
          <SmartMicChart
            v-bind:data="micChatData"
            v-bind:min_x="min_x"
            v-bind:max_x="max_x"
            v-if="this.sensorsData.predictions != undefined"
          />
        </div>

        <div class="conteudo smartmic">
          <table class="rTable6">
            <thead>
              <tr>
                <th>HISTÓRICO DE TOSSE</th>
                <th>SENSOR:</th>
                <th>01</th>
                <th>02</th>
                <th>03</th>
                <th>04</th>
                <th>05</th>
                <th>06</th>
                <th>07</th>
                <th>08</th>
              </tr>
            </thead>

            <tbody>
              <CoughHistoryRow v-bind:prediction="prediction" v-bind:key="prediction.id" v-for="(prediction, index) in sensorsData.predictions" v-bind:index="index"/>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageLoader from "../components/Loader.vue"
import LastFiveDaysSmartMicCard from "./LastFiveDaysSmartMicCard.vue"
import CoughHistoryRow from "./CoughHistoryRow.vue"
import SmartMicChart from "./SmartMicChart.vue"
import LastDate from '../components/LastDate.vue'
import { format, parseISO } from 'date-fns'
const MIC_COLOR_PALETTE = ["#99D98C", "#76C893", "#52B69A", "#34A0A4", "#168AAD", "#1A759F", "#1E6091", "#184E77"]

export default {
  name: "SmartMic",
  components: {
    LastFiveDaysSmartMicCard,
    CoughHistoryRow,
    PageLoader,
    SmartMicChart,
    LastDate
  },
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    chartFormattedPredictionsByMic(mic) {
      return this.sensorsData.predictions.map(item => {
        return {
          "x": item.day,
          "y": item.mic_data[mic].coughs
        }
      })
    },
  },
  computed: {
    lastPing() {
      const lastPing = this.selectSensorMic.data[0].datetime_last_ping
      return format(parseISO(lastPing), 'dd-MM-yyyy hh:mm' )
    },
    averageCoughValue() {
      return Math.round(this.sensorsData.predictions[0].mic_data.reduce((acc, next) => acc + next.coughs, 0)/8)
    },
    coughTrend() {
      try {
        let coughs = []
        for (let i = 0; i < this.sensorsData.predictions.length; i++) {
          coughs.push(this.sensorsData.predictions[i].mic_data.reduce((acc, next) => {
            if (acc == null) return next.coughs
            if (next.coughs == null) return acc
            return acc + next.coughs
          }, null))
        }

        let trend = 0
        coughs.reverse().reduce((current, next) => {
          if ([current, next].includes(null)) return next
          if (current === next) return next
          if (current < next) {
            trend++
            return next
          }
          trend--
          return next
        })
        switch (true) {
          case (trend > 1):
            return "Aumentando"
          case (trend < -1):
            return "Diminuindo"
          default:
            return "Estável"
        }
      }
      catch(err) {
        return "Estável"
      }
    },
    trendColor() {
      if (this.coughTrend == null) return "situacao-atual-neutral"

      let trend = this.coughTrend

      if (trend === "Aumentando") return "situacao-atual-ruim"
      if (trend === "Diminuindo") return "situacao-atual-bom"
      return this.situationColor
    },
    greaterCoughCount() {
      try {
        return this.sensorsData.length > 0 ? this.sensorsData.predictions[0].mic_data.reduce((current, next) => Math.max(current, next.coughs), 0) : []
      }
      catch (e) {
        return null
      }
    },
    situationText() {
      if (this.greaterCoughCount == null) return "Sem dado"

      let sum = this.greaterCoughCount

      if (sum >= 16) return "Crítico"
      if (sum >= 8) return "Alerta"
      return "Estável"
    },
    situationColor() {
      if (this.greaterCoughCount == null) return "situacao-atual-neutral"

      let sum = this.greaterCoughCount

      if (sum >= 16) return "situacao-atual-ruim"
      if (sum >= 8) return "situacao-atual-medio"
      return "situacao-atual-bom"
    },
    selectSensorMic() {
      return this.$store.getters.getSensorsListMic.find(obj => obj.batch.pk == this.batch.pk)
    },
    workingSensors() {  
      try {
        return this.selectSensorMic.sensor
      }
      catch(err) {
        return []
      }
    },
    sensorsData() {
      try {
        const data = this.sensorMicData.find(item => {
          if (item == null) return false
          return this.workingSensors.find(obj => item.sensor == obj.pk)
        })
        return data === undefined ? [] : data
      }
      catch(err) {
        return null
      }
    },
    barn() {
      return this.$store.getters.getBarnTarget
    },
    batch() {
      return this.$store.getters.getBatchTarget
    },
    sensorMicData() {
      return this.$store.getters.getSmartMicData
    },
    batchSensorMic(){
      return this.sensorMicData[0]?.batch ?? ""
    },
    semDados() {
      return this.sensorMicData[0]?.details ? true : false 
    }, 
    last_day_count() {
      const results = this.sensorsData.predictions[0].mic_data.some(item => item.coughs != null)
      if (!results) return {
        "total_count": null,
        "greater": null,
      }
      const last_day_data = {
        "total_count": Math.round(this.sensorsData.predictions[0].mic_data.reduce((acc, next) => acc + next.coughs, 0)),
        "greater": this.sensorsData.predictions[0].mic_data.reduce((current, next) => Math.max(current, next.coughs), 0),
      }
      return last_day_data
    },
    last_day() {
      if (this.batch && this.batch.date_departure != null) return this.batch.date_departure
      let today = new Date()
      today.setDate(today.getDate())
      return today
    },
    min_x() {
      const predictions = this.sensorsData.predictions
      if (!predictions) return this.last_day;
      return this.sensorsData.predictions[predictions.length - 1].day
    },
    max_x() {
      return this.sensorsData.length > 0 ? this.sensorsData.predictions[0].day : ''
    },
    micChatData() {
      if (!this.sensorsData.predictions) return;
      const setting = []
      for (let mic = 0; mic < this.sensorsData.predictions[0].mic_data.length; mic++) {
        setting.push({
          label: `Mic: ${mic + 1}`,
          data: this.chartFormattedPredictionsByMic(mic),
          type: 'bar',
          backgroundColor: `${MIC_COLOR_PALETTE[mic]}`,
          borderWidth: 0,
        })
      }

      return setting
    },
  },
  async created() {
    if (this.batchSensorMic !== this.batch.pk) {
      this.batch != null ? await this.$store.dispatch("fetchSmartMicData", this.batch.pk ) : this.loading = false
    }
    this.loading = false
  }
};
</script>

<style>
.chart {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 40px;
}

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.warning .text {
  background: #78b843;
  border-radius: 10px;
  color: #fff;
  padding: 20px;
  font-size: 30px;
}

.warning .text h1 {
  display: flex;
  justify-content: center;
}

.warning .text p {
  display: flex;
  justify-content: center;
  margin: 0;
}

#chartjs-tooltip {
  background: #fff;
  padding: 0 18px;
  border-radius: .25em;
}

#chartjs-tooltip table {
  display: flex;
  flex-direction: row;
}

#chartjs-tooltip table thead {
  margin: 0 6px 0 0;
}

#chartjs-tooltip table thead tr th .entrada-barracao {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F2F4F6;
  float: left;
  padding: 100% 6px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 40px;
  text-align: center;
}

#chartjs-tooltip table tbody tr {
  margin-top: 20px;
}

#chartjs-tooltip table tbody tr td span div {
  margin: 0;
  padding: 0;
  width: 90px;
}

#chartjs-tooltip table tbody tr td span div .smartmic-sensor-tosse {
  width: 100%;
  margin: 0 6px 0 0;
}

#chartjs-tooltip table tbody tr td span div .smartmic-sensor-tosse span {
  height: 94px;
  float: left;
  margin-bottom: 6px;
}

@media screen and (max-width: 990px) {
  #chartjs-tooltip table tbody tr td span div {
    width: 60px;
  }
}

@media screen and (max-width: 768px) {
  #mic {
    font-size: 12px;
  }

  #chartjs-tooltip table tbody tr td span div {
    width: 40px;
  }

  #chartjs-tooltip table tbody tr td span div .smartmic-sensor-tosse span {
    height: 64px;
  }

  #chartjs-tooltip table thead tr th .entrada-barracao {
    margin-top: 20px;
    padding: 80% 6px;
  }

  .warning {
    height: 250px;
  }

  .warning h1 {
    font-size: 30px;
  }

  .warning p {
    font-size: 20px;
  }
}

@media screen and (max-width: 520px) {
  #mic {
    font-size: 12px;
  }

  #chartjs-tooltip table tbody tr td span div {
    width: 30px;
  }

  #chartjs-tooltip table tbody tr td span div .smartmic-sensor-tosse span {
    height: 36px;
  }

  #chartjs-tooltip table thead tr th .entrada-barracao {
    padding: 50% 6px;
  }
}

@media screen and (max-width: 480px) {
  #chartjs-tooltip table tbody tr td span div {
    width: 24px;
  }

  #chartjs-tooltip table tbody tr td span div .smartmic-sensor-tosse span {
    height: 34px;
  }

  #chartjs-tooltip table thead tr th .entrada-barracao {
    padding: 50% 6px;
  }

  .warning h1 {
    font-size: 25px;
  }

  .warning p {
    font-size: 15px;
  }
}

@media screen and (max-width: 360px) {
  .warning {
    height: 200px;
  }

  .warning h1 {
    font-size: 20px;
  }

  .warning p {
    font-size: 10px;
  }
}
</style>
