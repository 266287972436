<template>
  <div>
    <p class="copyright">Copyright © PecSmart {{year}}</p>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  computed:{
    year(){
      return new Date().getFullYear();
    }
  }


};
</script>

<style scoped>
.copyright {
  text-align: center;
  color: #9a9a9a;
  font-size: 14px;
  font-weight: 300;
  padding: 20px 0 20px 0;
}
</style>
