<template>
  <div id="batch-tab">
    <Loader v-if="loading" /> 
    <div v-else class="main-container container">
      <div style="padding-top: 32px; padding-bottom: 32px">
        <h4>Animais:</h4>
        <div class="detalhar-lote" style="padding:0px">
          <RegisterButtons :batchDetail="batchDetail" :batch="batch"/>
        </div>
      </div>

      <div class="divider" v-if="modalDeparture == null"></div>
      <div v-if="firstBatch && barn !== null">
        <div v-if="batch == null || batchDetail.date_departure !== null && isAdmin" style="display: flex; justify-content: center; align-items: center;" class="batch-container register-batch-not-admin">
          <div>
            <button data-toggle="modal" data-target="#batchModal">
              <h1>Nenhum lote</h1>
              <p>clique para cadastrar</p>
            </button>
          </div>
        </div>
      </div>
      <Loader v-if="barn == null" /> 

      <div class="batch-container" v-if="!firstBatch || batch !== null && batch.date_departure == null">
        <h4>Lote {{batch.pk}}:</h4>
        <div v-if="batch != null" class="batch-info-container">
          <div style="display: flex; flex-wrap: wrap;">
            <div class="batch-card">              
              <div>
                <p>Peso Inicial</p>
                <span>{{batchDetail.average_initial_weight}} kg</span>
              </div>

              <div>
                <p>Peso Final</p>
                <span>{{final(batchDetail.average_final_weight)}}</span>
              </div>

              <div>
                <p>Peso Desejado</p>
                <span>{{batchDetail.desired_weight}} kg</span>
              </div
              >
              <div>
                <p>Qtd Inicial</p>
                <span>{{batchDetail.initial_count}}</span>
              </div>
              
              <div>
                <p>Qtd Final</p>
                <span>{{final(batchDetail.final_count)}}</span>
              </div>

              <div>
                <p>Perdas</p>
                <span>{{batchDetail.loss}}</span>
              </div>
            </div>
          </div>

          <div class="batch-date-modal">
            <div class="date-input">
              <div class="calendario">
                Data inicial: <br />
                <input disabled id="date_init" class="form-control" type="date" :value="batchDetail.date_accomodation"/>
              </div>

              <div class="calendario-final">
                Data final: <br />
                <div v-if="batchDetail.date_departure == null" class="message">Lote em aberto</div>
                <input v-else disabled id="date_finish" class="form-control" type="date" :value="batch.date_departure"/>
              </div>
            </div>

             <div v-if="isAdmin && batch.date_departure == null" class="batch-modal-buttons-container">
              <div class="batch-modal-button-edit-batch" data-toggle="modal" data-target="#EditBatchModal">
                <a id="atualizar" class="btn btn-primary text-white">EDITAR LOTE</a>
              </div>
              
              <div class="batch-modal-button-end-batch" data-toggle="modal" data-target="#EndBatchModal">
                <a id="atualizar" class="btn btn-primary text-white">{{modalMessage}}</a>
              </div>
            </div> 
            <div class="button-cadastrar"  v-if="batch.date_departure !== null">
              <button data-toggle="modal" data-target="#batchModal">
                CADASTRAR LOTE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalStyle>
      <RegisterBatchModal />
      <EndBatchModal />
      <EditBatchModal :batchDetail="batchDetail"/>
    </ModalStyle>
  </div>
</template>

<script>
import RegisterBatchModal from "../components/RegisterBatchModalOld.vue";
import EndBatchModal from "../components/EndBatchModal.vue";
import EditBatchModal from "../components/EditBatchModal.vue";
import ModalStyle from "../components/ModalStyle.vue";
import RegisterButtons from "./RegisterButtons.vue";
import Loader from "./Loader.vue";

export default {
  name: "BatchTab",
  components: {
    ModalStyle,
    EndBatchModal,
    EditBatchModal,
    RegisterBatchModal,
    RegisterButtons,
    Loader
  },
  data() {
    return{
      loading:true,
    }
  }, 
  methods: {
    final(value) {
      try {
        if (value == null) return "Em Andamento"
        return value
      }
      catch(err) {
        return "Em Andamento"
      }
    },
    async getBatchDetail() {
      await this.$store.dispatch('fetchBatchDetail', this.batch.pk)
    }
  },
  computed: {
    modalAccommodation() {
      if (this.batch == null) return null
      return this.$store.getters.getBatchDetail.date_accomodation
    },
    modalDeparture() {
      if (this.batch == null) return null
      return this.$store.getters.getBatchDetail.date_departure
    },
    modalAverageInitialWeight() {
      if (this.batch == null) return null
      return this.$store.getters.getBatchDetail.average_initial_weight
    },
    modalDesiredWeight() {
      if (this.batch == null) return null
      return this.$store.getters.getBatchDetail.desired_weight
    },
    modalInitialCount() {
      if (this.batch == null) return null
      return this.$store.getters.getBatchDetail.initial_count
    },
    modalMessage() {
      try {
        if (this.batch.date_departure == null) return 'FINALIZAR LOTE'
        return 'EDITAR FINALIZAÇÃO'
      }
      catch(err) {
        return 'EDITAR FINALIZAÇÃO'
      }
    },
    workingSensors() {
      try {
        return this.barn.sensors.filter((sensor) => sensor.type === "smartfeed");
      } catch (err) {
        return [];
      }
    },
    barn() {
      return this.$store.getters.getBarnTarget;
    },
    batch() {
      return this.$store.getters.getBatchTarget;
    },
    firstBatch() {
      if (this.batch == null) {
        return true
      }
      return false
    },
    batchDetail() {
      return this.$store.getters.getBatchDetail[0]
    },
    isAdmin() {
      return this.$store.getters.getIsAdmin;
    },
    barnSensors() {
      return this.barn.sensors?.filter(item => item.type != 'smartmic_dummy_V3' && item.type != 'smartmic_central_V3')
    }
  },
  watch:{
    batch(newValue) {
      if (newValue !== null) {
        this.getBatchDetail()
        this.loading = false
      }
    }
  },
  async created() {
    if (this.batch !== null) {
      await this.$store.dispatch('fetchBatchDetail', this.batch.pk)
      this.loading = false
    }
    this.loading = false
  }
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  padding: 32px 24px 0 24px;
}

.message {
  min-height: 38px;
  display: flex;
  align-items: center;
  background-color: #e9ecef;
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.barn-container {
  width: 100%;
}

.batch-container {
  width: 100%;
  padding: 32px 0 0 0;
}

.old-batch-container {
  width: 100%;
}

.batch-information-cards {
  width: 50%;
}

.batch-date-modal {
  display: flex;
  flex-direction: row;
}

.divider{
  background: #c7c7c7;
  width: 100%;
  height: 1.75px;
}

.batch-info-container {
  display: flex;
  flex-direction: column;
}

.batch-info-container-ended-batch {
  display: flex;
  flex-direction: row;

  padding-top: 58px;
}

.sensor-list-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px 0;
}

.list-container {
  width: 100%;
  display: flex;
  font-size: 18px;
  padding: 12px 0;
  flex-direction: row;
}

.date-input {
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-right: 5px;
}

.register-batch button {
  width: 280px;
  height: 100px;
  background: #fff;
  border: 4px solid #78b843;
  border-radius: 10px;
  outline: none;
  transition: 0.2s ease-out;
}

.register-batch button h1 {
  font-size: 32px;
  margin: 8px 0 -10px 0;
  color: #78b843;
}

.register-batch button:hover {
  background: #78b843;
}

.register-batch button:hover h1,
.register-batch button:hover p{
  color: #fff;
}

.register-batch button p {
  font-size: 24px;
  color: #78b843;
  padding: 10px;
  margin: 0;
}

.register-batch-not-admin button {
  width: 280px;
  height: 100px;
  background: #fff;
  border: 4px solid #78b843;
  border-radius: 10px;
  outline: none;
  transition: 0.2s ease-out;
  cursor: default;
}

.register-batch-not-admin button h1 {
  font-size: 32px;
  margin: 8px 0 -10px 0;
  color: #78b843;
}

.register-batch-not-admin button p {
  font-size: 24px;
  color: #78b843;
  padding: 10px;
  margin: 0;
}

.batch-modal-buttons-container {
  display: flex;
  flex-direction: row;
  width: 50%;
  padding: 10px 0 0 5px;
}

.batch-modal-button-end-batch {
  width: 50%;
  padding: 0 0 0 5px;
}

.batch-modal-button-edit-batch {
  width: 50%;
  padding: 0 5px 0 0;
}

.batch-card {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.batch-card div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  margin: 4px;
  height: 100px;
  width: 170px;
  
  background: white;
}

.batch-card div p {
  margin-bottom: 4px;
  font-size: 20px;
  color: #6B6C6C;
}

.batch-card div span {
  color: #78b843;
  font-size: 18px;
  font-weight: 600;
}

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.warning .text {
  background: #78b843;
  border-radius: 10px;
  color: #fff;
  padding: 20px;
  font-size: 30px;
}

.warning .text h1 {
  display: flex;
  justify-content: center;
}

.warning .text p {
  display: flex;
  justify-content: center;
  margin: 0;
}

.button-cadastrar{
  display: flex;
  align-items: flex-end;
  margin-bottom:12px ;
}

.button-cadastrar button{
  background-color: #78b843;
  color: #fff;
  border: none;
  border-radius:8px ;
  min-height: 38px;
  min-width: 265px;
}

@media screen and (max-width: 1199px) {
  .batch-card div {
    height: 100px;
    width: 147px;
  }

  .batch-card div p {
    font-size: 18px;
  }

  .batch-card div span {
    font-size: 16px;
  }

  .batch-info-container {
    padding-top: 32px;
  }

  .batch-info-container-ended-batch {
    padding-top: 32px;
  }
}

@media screen and (max-width: 991px) {
  .batch-date-modal {
    flex-direction: column;
  }

  .batch-modal-buttons-container {
    flex-direction: row;
    padding: 0;
    width: 100%;
  }

  .date-input {
    flex-direction: row;
    padding: 0;
    width: 100%;
  }

  .batch-card div {
    width: 220px;
  }
}


@media screen and (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .batch-info-container {
    padding: 0px;
  }

  .batch-info-container-ended-batch {
    padding: 0px;
    flex-direction: column;
  }

  .sensor-list-container {
    justify-content: center;
  }

  .batch-modal-buttons-container {
    flex-direction: row;
  }

  .batch-modal-button-end-batch {
    width: 50%;
    padding: 0 0 0 5px;
  }

  .batch-modal-button-edit-batch {
    width: 50%;
    padding: 0 5px 0 0;
  }

  .batch-card div {
    width: 162px;
  }

  .date-input {
    flex-direction: row;
  }

  .batch-information-cards {
    width: 100%;
  }

  .warning {
    height: 250px;
  }

  .warning h1 {
    font-size: 30px;
  }

  .warning p {
    font-size: 20px;
  }
}

@media screen and (max-width: 551px) {
  .batch-card div {
    width: 30%;
  }
}

@media screen and (max-width: 480px) {
  .batch-card div {
    width: 45%;
  }

  .warning h1 {
    font-size: 25px;
  }

  .warning p {
    font-size: 15px;
  }

  .batch-modal-buttons-container {
    flex-direction: column;
  }

  .batch-modal-button-end-batch {
    width: 100%;
    padding: 0;
  }

  .batch-modal-button-edit-batch {
    width: 100%;
    padding: 0;
  }

  .date-input {
    flex-direction: column;
  }
}

@media screen and (max-width: 360px) {
  .batch-card div {
    width: 46%;
  }

  .warning {
    height: 200px;
  }

  .warning h1 {
    font-size: 20px;
  }

  .warning p {
    font-size: 10px;
  }
}
</style>
