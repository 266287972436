<template>
  <!-- Cadastrar Baixa Modal-->
  <div
    class="modal fade"
    id="baixaModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <img src="../assets/ico-lapide.svg" /> CADASTRAR BAIXA
          </h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="registerLoss(batch.pk, weight, reason, death_details, datetime_registered, quantity)">
            <label>Barracão: {{barnName}}</label><br />
            
            <label>Peso do Animal: </label><br />
            <input
              type="number"
              class="form-control"
              placeholder="Peso em kg"
              v-model="weight"
              required
            />

            <label>Motivo</label><br />
            <!-- Puxar usuários -->
            <select class="form-control" v-model="reason" required>
              <option value="pneumonia">Pneumonia</option>
              <option value="diarrhea">Diarreia</option>
              <option value="blood diarrhea">Diarreia com sangue</option>
              <option value="sudden death">Morte súbita</option>
              <option value="twist">Torção</option>
              <option value="refuse/eliminated">Refugo/Eliminado</option>
              <option value="ulcer">Úlcera</option>
              <option value="prolapse">Prolapso</option>
              <option value="encephalitis">Encefalite</option>
              <option value="other">Outros</option>
            </select>

            <textarea
              name="observacoes"
              id="inputObservacoes"
              form="pendenciaform"
              placeholder="Detalhes da morte"
              v-model="death_details"
            ></textarea>

            <label>Data e Hora</label><br />
            <input
              type="datetime-local"
              class="form-control"
              v-model="datetime_registered"
              required
            />

            <label>Quantidade de Baixas: </label><br />
            <input
              type="number"
              class="form-control"
              placeholder=""
              v-model="quantity"
              step="1"
              required
            />

            <button type="submit" class="modal-btn">Cadastrar</button>
          </form>
        </div>
        <!-- end modal body -->
      </div>
    </div>
    <SuccessAlert ref="successAlert" v-bind:message="messageSuccess"/>
    <ErrorAlert ref="errorAlert" v-bind:message="messageFailure"/>
  </div>
  <!-- end Cadastrar Baixa  -->
</template>

<script>
import ErrorAlert from "./ErrorAlert.vue";
import SuccessAlert from "./SuccessAlert.vue";

export default {
  name: "LossModal",
  components: {
    ErrorAlert,
    SuccessAlert
  },
  data() {
    return {
      weight: null,
      reason: "pneumonia",
      death_details: "",
      datetime_registered: null,
      quantity: null,
      messageSuccess: "baixa cadastrada",
      messageFailure: "não foi possível registrar baixa"
    }
  },
  methods: {
    async registerLoss(batch, weight, reason, death_details, datetime_registered, quantity) {
      await this.$store.dispatch("registerLoss", {batch, weight, reason, death_details, datetime_registered, quantity})
      if (!(this.$store.getters.getError)) {
        this.$refs.errorAlert.closeAlert()
        this.$refs.successAlert.alert()
        this.handleClose()
      }
      else {
        this.$refs.successAlert.closeAlert()
        this.$refs.errorAlert.alert()
      }
    },
    handleClose() {
      this.$refs.closeButton.click();
    }
  },
  computed: {
    barnName() {
      if (this.barn == null) return ""
      return `${this.barn.name}`
    },
    barn() {
      return this.$store.getters.getBarnTarget
    },
    batch() {
      return this.$store.getters.getBatchTarget
    },
  }
}
</script>

<style scoped>
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  padding-left: 10px;
}

select {
  margin: 0px;
  margin-top: 5px;
  padding-left: 0px;
}

.modal-body select {
  padding-left: 6px;
}

.modal-body label {
  padding-left: 12px;
}
</style>
