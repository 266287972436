<template>
  <div class="conteudo smartfeed-silo">
    <h2>Nome: {{sensor.name}}</h2>

    <img src="./../assets/silo1.svg" />
    <div style="font-size: 14px">
      <p><span> Peso Atual: </span>{{computed_weight}}</p>
      <p><span>Nível Atual: </span> {{computed_level}}</p>
      <p><span>Medido: </span>{{lastTime_data}}</p>
      <p><span>Online: </span>{{onlineData}}</p>
    </div>

    <div :class="color">
      <!-- CLASSES: nivel-atual-bom | nivel-atual-medio | nivel-atual-ruim -->
      <div class="progress-bar" role="progressbar" :style="`height: ${percentage}%`"></div>
    </div>

    <div class="clear"></div>
    <img src="./../assets/silo2.svg" />
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'


export default {
  name: "Silo",
  props: ["level", "weight", "sensor", "useInformedWeight"],
  computed: {
    computed_level() {
      if (this.level == null) return "Sem Nível"
      if (this.useInformedWeight) return `${(this.level * 0.9).toFixed(2)} %`
      return `${this.level} %`
    },
    computed_weight() {
      if (this.weight == null) return "Sem Peso"
      if (this.informedMaxWeight == null) return `${this.weight} kg`
      if (this.useInformedWeight) return `${(this.informedMaxWeight * this.level * 0.009).toFixed(2)} kg`
      return `${this.weight} kg`
    },
    company() {
      return this.farm.farm.company
    },
    barn() {
      return this.$store.getters.getBarnTarget 
    },
    farm() {
      return this.$store.getters.getAllBarns.find((obj)=> {
        return obj.barn.find((barn)=>barn.pk == this.barn.pk)
      })
    },
    informedMaxWeight(){
      return this.sensor.informed_max_weight
    },
    percentage() {
      if (this.level == null) return 100
      if (this.useInformedWeight) return this.level * 0.9
      return this.level
    },
    color() {
      if (this.level == null) return "progress progress-bar-vertical disabled"

      if (this.level > 30) {
        return "progress progress-bar-vertical nivel-atual-bom"
      }
      if (this.level > 15) {
        return "progress progress-bar-vertical nivel-atual-medio"
      }
      return "progress progress-bar-vertical nivel-atual-ruim"
    },
    lastTime_data() {
      const originalDate = this.sensor.datetime;
      const newDate = format(parseISO(originalDate), "dd/MM/yyyy HH:mm");

      return newDate
    },
    onlineData() {
      const originalDate = this.sensor.datetime_last_ping;
      const newDate = format(parseISO(originalDate), "dd/MM/yyyy HH:mm");
      return newDate
    }
  }
};
</script>

<style scoped>
.smartfeed-silo{
  min-width: 250px;
  max-width: 300px;
  text-align: center ;

}

span{
  color: #87da44;
  font-weight: 500
}
</style>
