<template>
  <div>
    <Header v-bind:name="name">
      <div class="header-buttons">
        <div v-if="isAdmin" class="register-btn-container">
          <a v-if="!loading && isPamplonaCompanyAdmin" class="header-button" style="margin-right: 10px; display: flex; align-items: center;" title="BI" :href="pamplonaBiUrl" target="_blank">
            BI
          </a>

          <a v-if="loading" style="cursor: not-allowed;" class="header-icon">
            <img style="margin: 0; color: white;" src="./../assets/plus.svg"/>
            <div class="dropdown-menu"></div>
          </a>
        </div>

        <div class="history-buttons" >
          <router-link :to="'/batches-history'" v-if="!loading">
            <button class="header-button" title="Ir para histórico de lotes" >
              Ir para histórico de lotes
            </button>
          </router-link>

          <router-link :to="'/batches-history'" v-if="!loading && loadingTotal == 0">
            <button class="header-icon" title="Ir para histórico de lotes">
              <img class="history-icon" src="./../assets/history-icon.svg"/>
            </button>
          </router-link>

          <button style="cursor: not-allowed;" class="header-icon" v-if="loading" title="Ir para histórico de lotes">
            <img class="history-icon" src="./../assets/history-icon.svg"/>
          </button>

          <button class="header-button history-disabled" v-if="loading" title="Ir para histórico de lotes">
            Ir para histórico de lotes
          </button>
        </div>
        <form class="farm-dropdown" v-if="userLevel == 'pecsmart'">
          <select name="comapanies" v-model="optionCompanies" class="select-companies" :class="colorSelectCompanies">
            <option>Companies</option>
            <option :key="company.pk" v-for="company in companies" :value="company">
              {{company.name}}
            </option>
          </select>
        </form>
        <form class="farm-dropdown">
          <div>
            <select  v-if="userLevel === 'pecsmart'" name="granjas" v-model="optionFarmPecsmart" :class="colorSelectFarm">
              <option >Pecsmart Support</option>
              <option >Todas as Granjas</option>
              <option v-bind:key="farm.id" :value="farm" v-for="farm in allFarms">
                {{farm.name}}
              </option>
            </select>
            <select v-else name="granjas" v-model="optionFarm">
              <option >Todas as Granjas</option>
              <option v-bind:key="farm.id" v-for="farm in allFarms" :value="farm">
                {{farm.name}}
              </option>
            </select>
          </div>
        </form>
      </div>
    </Header>
    <PageLoader v-if="loading"/>
    <div class="container page-animation" :style="style">
      <div class="row">
        <div class="col-lg-12">
          <div class="conteudo galpoes">
            <div class="message" v-if="selectedFarms.length === 0 && userLevel == 'pecsmart'">Selecione uma fazenda ou uma empresa</div>
            <Farm v-bind:farm="farm" v-bind:key="farm.id" v-for="farm in selectedFarms"/>
          </div>
          <Copyright />
        </div>
      </div>
    </div>

    <ModalStyle>
      <RegisterFarmModal v-if="showModalFarm" @close="closeModal()" />
      <EditFarmAltModal v-bind:allFarms="allFarms" v-if="showModalEditFarm" @close="showModalEditFarm = false" />
      <RegisterBarnAltModal v-bind:allFarms="allFarms" v-if="showModalBarn" @close="closeModal()" />
    </ModalStyle>
  </div>
</template>

<script>
import PageLoader from "../components/Loader.vue";
import Farm from "../components/FarmV2.vue";
import Copyright from "../components/Copyright.vue";
import Header from "../components/Header.vue";
import RegisterFarmModal from "../components/RegisterFarmModal.vue";
import RegisterBarnAltModal from "../components/RegisterBarnAltModal.vue";
import ModalStyle from "../components/ModalStyle.vue";
import EditFarmAltModal from "../components/EditFarmAltModal.vue"

export default {
  name: "HomePage",
  beforeRouteEnter(to, from, next) {
    to.meta.title = "PecSmart - Início";
    document.title = to.meta.title;
    next();
  },
  components: {
    Farm,
    Copyright,
    Header,
    RegisterFarmModal,
    RegisterBarnAltModal,
    ModalStyle,
    PageLoader,
    EditFarmAltModal,
  },
  data() {
    return {
      loading: true,
      optionCompanies: 'Companies',
      style: "display: none;",
      name: "INÍCIO",
      optionFarm:  "Todas as Granjas",
      optionFarmPecsmart: "Pecsmart Support",
      showModalBarn: false,
      showModalFarm: false,
      showModalEditFarm: false,
      showByFarm: true,
      showByCompanies: false
    }
  },
  async created() {
    this.$store.commit('cleanData')
    if (this.userLevel === 'pecsmart') {
        this.$store.commit('cleanFazendas')
    }
    if ((typeof window.localStorage.getItem("username") === typeof 'string') && (this.status === false)) {
      this.$store.commit("setNavbar", true)
      const user = {
        username: window.localStorage.getItem("username")
      }
      const isLoginPage = false
      await this.$store.commit("setHeader", window.localStorage.getItem("Token"))
      await this.$store.dispatch("login", {user, isLoginPage})
      await this.$store.dispatch("startHome")
      if (this.userLevel !== 'pecsmart') await this.$store.dispatch("fetchCompanySettings", this.company)
      if (this.userLevel == 'pecsmart') await this.$store.dispatch("fetchCompanies")
      this.loading = false
      this.style = ""
    }
    else {
      if (this.status === true) {
        this.loading = false
        this.style = ""
      }
      else {
        await this.$store.dispatch("startHome")
        this.loading = false
        this.style = ""
        this.$router.push('/')
      }
    }
  },
  computed: {
    allFarms() {
      this.$store.dispatch("setSelectedFarms", this.$store.getters.getAllFarms)
      return this.$store.getters.getAllFarms
    },
    status() {
      return this.$store.getters.getLogedIn;
    },
    isAdmin() {
      return this.$store.getters.getIsAdmin;
    },
    company(){
      return this.$store.getters.getCurrentUser.company
    },
    companies() {
      return this.$store.getters.getCompanies
    },
    selectedFarms() {
      const optionType = this.userLevel !== 'pecsmart' ? this.optionFarm : this.optionFarmPecsmart

      if (this.optionFarmPecsmart == 'Todas as Granjas') return this.allFarms
      if (this.optionFarm == 'Todas as Granjas' && this.userLevel !== 'pecsmart') return this.allFarms
      if (this.userLevel !== 'pecsmart') return this.filterFarm(optionType)
      return this.showByFarm ? this.filterFarm(optionType) : this.filterCompanies(this.optionCompanies)
    },
    colorSelectFarm() {
      return this.showByFarm && this.userLevel == 'pecsmart' ? 'select-toggle' : ''
    },
    colorSelectCompanies() {
      return this.showByCompanies && this.userLevel == 'pecsmart' ? 'select-toggle' : ''
    },
    selectedFarmByCompanies() {
      return this.filterCompanies(this.option)
    },
    isPamplonaCompanyAdmin() {
      // TODO: Change this to get information from the backend about using the button or not
      const user = this.$store.getters.getCurrentUser

      return user.company === 4 && user.role === "company_admin"
    },
    loadingTotal() {
      return this.$store.getters.getActiveRequest
    },
    userLevel() {
      return this.$store.getters.getUserLevel
    },
    pamplonaBiUrl() {
      // TODO: Change this to get URL for the BI from the backend
      return 'https://app.powerbi.com/view?r=eyJrIjoiMjhkZmU1YzktNmJiOC00Y2FkLTk1NWQtM2ZlMDcwMWViMzg3IiwidCI6Ijk2ODlhOGFmLWI4NjItNDZiMS04MWYzLTAzNjA2NjkzZTBjMSJ9&pageName=ReportSection713ed991c08e7816670a'
    },
  },
  methods: {
    closeModal() {
      this.showModalBarn = false
      this.showModalFarm = false
    },
    updateOption(value) {
      this.optionSelected = value
    },
    filterCompanies(value) {
      return this.allFarms.filter((farm)=>{
        return farm.company == value.pk
      })
    },
    filterFarm(value) {
      return this.allFarms.filter((farm)=>{
        return farm.name == value.name
      })
    } 
  },
  watch:{
    optionCompanies (newValue) {
      if (newValue !== 'Companies') {
        this.showByFarm = false
        this.showByCompanies = true
        this.optionFarmPecsmart = 'Pecsmart Support'
      }
     
    },
    optionFarm () {
      this.showByFarm = true
      this.showByCompanies = false
      
    },
    optionFarmPecsmart (newValue) {
      if (newValue !== 'Pecsmart Support') {
        this.showByFarm = true
        this.showByCompanies = false
        this.optionCompanies = 'Companies'
      }
      
    }
  }
};
</script>

<style scoped>
.page-animation {
  animation:down 0.5s ease;
  padding: 0px;
}

.cursor{
  cursor: not-allowed;
}

.message{
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.container{
  margin: auto;
}
@keyframes down {
  0% {
    opacity: 0;
    transform:translateY(-50px);
  }
  100% {
    opacity: 1;
    transform:translateY(0px);
  }
}

.galpoes .rTable3 {
  margin-bottom: 30px;
}

.galpoes .rTable3:last-child {
  margin-bottom: 0;
}

.select-companies{
  min-width: 343px;
}

.select-toggle{
  border: 4px solid #1b4238;
  color: #1b4238;
  font-weight: 600;
}

.header-buttons{
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (min-width: 991px) {
  .header-buttons{
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}



</style>
