<template>
  <div
    class="modal fade"
    id="batchModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <img class="register-icon" src="../assets/ico-edit.svg" /> CADASTRAR LOTE
          </h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <Loader v-if="loading"/>
        <div v-else class="modal-body">
          <form @submit.prevent="registerBatch(animal_type, average_initial_weight, desired_weight, initial_count, date_accomodation)">
            <label>Barracão: {{barnName}}</label><br />
            
            <label for="animal-type">Tipo do Animal</label>
            <select v-model="animal_type" class="form-control" name="animal-type" required>
              <option :value="animal_type.pk" v-bind:key="animal_type.pk" v-for="animal_type in animalTypes">
                {{animal_type.name}}
              </option>
            </select>
            <label>Peso Médio Inicial</label><br />
            <input
              type="number"
              step="any"
              class="form-control"
              placeholder="Peso em kg"
              v-model="average_initial_weight"
              required
            />

            <label>Peso Desejado</label><br />
            <input
              type="number"
              step="any"
              class="form-control"
              placeholder="Peso em kg"
              v-model="desired_weight"
              required
            />

            <label>Quantidade Inicial de Animais </label><br />
            <input
              type="number"
              step="1"
              class="form-control"
              placeholder="Quantidade de Animais"
              v-model="initial_count"
              required
            />

            <label>Data de Acomodação</label><br />
            <input
              type="date" 
              placeholder="dd-mm-yyyy"
              class="form-control"
              v-model="date_accomodation"
              required
            />
            <button type="submit" :class="button">Cadastrar</button>
          </form>
        </div>
      </div>
    </div>
    <SuccessAlert ref="successAlert" v-bind:message="messageSuccess"/>
    <ErrorAlert ref="errorAlert" v-bind:message="messageFailure"/>
  </div>
</template>

<script>
import ErrorAlert from "./ErrorAlert.vue";
import SuccessAlert from "./SuccessAlert.vue";
import Loader from "../components/Loader.vue";
import { URL } from "../store/index"

export default {
  name: "RegisterBatchModal",
  components: {
    ErrorAlert,
    SuccessAlert,
    Loader,
  },
  data() {
    return {
      average_initial_weight: '',
      desired_weight: '',
      initial_count: '',
      date_accomodation: '',
      animal_type: null,
      messageSuccess: "Lote cadastrado",
      messageFailure: "Não foi possível registrar o lote",
      button: "button",
      loading: true,
    }
  },
  methods: {
    async registerBatch(animal_type, average_initial_weight, desired_weight, initial_count, date_accomodation) {
      if (this.button === "button-disable") return
      
      document.body.style.cursor = 'progress';
      this.button = "button-disable"

      const barn = this.barn.pk
      const response = await this.$store.dispatch("registerBatch", {barn, animal_type, average_initial_weight, desired_weight, initial_count, date_accomodation})
      if (response.ok) {
        this.$refs.errorAlert.closeAlert()
        this.$refs.successAlert.closeAlert()
        this.$refs.successAlert.alert()
        this.updateDataBatch()
        this.handleClose()
      }
      else {
        this.$refs.errorAlert.closeAlert()
        this.$refs.successAlert.closeAlert()
        this.$refs.errorAlert.alert()
      }
      
      document.body.style.cursor = 'default';
      this.button = "button"
      this.$emit('newBatchData', response.batch)
    },
    handleClose() {
      this.$refs.closeButton.click();
    },
    async updateDataBatch(){
      try {
        let url_to_fetch = `${URL}batches/report/home/barn=${this.barn.pk}`
        await fetch(url_to_fetch, {headers: this.$store.getters.getHeader, cache: 'reload'})
        .then(response => response.json())
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    barnName() {
      if (this.barn == null) return ""
      return `${this.barn.name}`
    },
    farm() {
      return this.$store.getters.getAllBarns.find(obj=>{
        return obj.barn.find(element => element.pk == this.$store.getters.getBarnTarget.pk)
      })
    },
    barn() {
      return this.$store.getters.getBarnTarget;
    },
    batch() {
      return this.$store.getters.getBatchTarget
    },
    animalTypes() {
      return this.$store.getters.getAnimalTypes?.filter(type => type.company === this.farm.farm.company);
    },
  },
  async created() {
    const company = this.$store.getters.getCurrentUser.company
    await this.$store.dispatch("fetchAnimalTypes", company)
    this.loading = false
  }
}
</script>

<style scoped>
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    padding-left: 10px;
}

select {
    margin: 0px;
    margin-top: 5px;
    padding-left: 0px;
}

.modal-body select {
    padding-left: 6px;
}

.modal-body label {
  padding-left: 12px;
}

.register-icon {
  margin-bottom: 4.5px;
}

.button {
  color: #fff;
  background-color: #78b843;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
}

.button:hover {
  background-color: #1b4238;
  color: #fff;
  text-decoration: none;
}

.button-disable {
  color: #fff;
  background-color: #999999;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
  cursor: not-allowed;
  outline: none;
}
</style>
