<template>
  <div class="data-container">
    <iframe
      title="Reporte_BRF"
      width="100%"
      height="2000px"
      :src="biURL"
      frameborder="0"
      allowFullScreen="true" />
  </div>
</template>

<script>

export default {
  name: "EmbeddedBI",
  computed: {
    biURL() {
      // TODO: Change this to get URL for the BI from the backend
      return 'https://app.powerbi.com/view?r=eyJrIjoiMTIwN2UzNWUtN2MwNi00ODVkLWE5MGMtNmFjNWM1MzVlZWM5IiwidCI6Ijk2ODlhOGFmLWI4NjItNDZiMS04MWYzLTAzNjA2NjkzZTBjMSJ9'
    },
  }
};
</script>

<style scoped>
.data-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px;
}


@media screen and (max-width: 480px) {
  .data-container {
    flex-direction: column;
  }
}
</style>
