<template>
  <div>
    <a class="card-button" @click="changeBarnTarget(barn, batch, sensors, '/batch-details/smart-feed')">
      <AnimadePlaceHolder v-if="this.sensors.length == 0" height="40px" width="100%"  borderRadius="4px"/>
      <div class="feed-data-wrapper" v-else>
        <FeedData :sensors="sensors" :indexBarn="indexBarn" :barn="barn" :batch="batch" :feed="feed" v-for="feed in orderSensors" :key="feed.sensor"/>
      </div> 
    </a>
  </div>
</template>

<script>
import FeedData from "../components/FeedData.vue"
import AnimadePlaceHolder from './AnimadePlaceHolder.vue'

export default {
  name: "SensorsFeed",
  components: {
    AnimadePlaceHolder,
    FeedData
  },
  data() {
    return {
      dataFeedFromFetch:[],
      loading: true
    }
  },
  props: ["sensors", "indexBarn", 'barn', 'batch'],
  methods: {
    changeBarnTarget(barn, batch, sensors, route) {
        this.$store.commit("setBarnTarget", barn)
        this.$store.commit("setBatchTarget", batch[0] == undefined ? null : batch[0])
        this.$router.push(route)
    },
  },
  computed: {
    orderSensors() {
       return [...this.sensors].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
  
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },
  },
  async created() {
    // console.log(this.sensors)
    // console.log(this.orderSensors)
  }
};

</script>

<style scoped>
.cursor{
  cursor: not-allowed;
}

.progress {
  border-radius: 0;
  height: 40px;
  background-color: #f4f4f4;
  font-weight: bold;
  font-size: 14px;
}

.progress-bar {
  text-align: left;
  padding-left: 10px;
}


a {text-decoration: none;}
.peso-atual-acima .progress-bar {background: #415430;}
a .peso-atual-acima .progress-bar:hover {background: #273619;}
.peso-atual-bom .progress-bar {background: #a1cd7b;}
a .peso-atual-bom .progress-bar:hover {background: #5e7d42;}
.peso-atual-medio .progress-bar {background: #e2bf65;}
a .peso-atual-medio .progress-bar:hover {background: #c59e3a;}
.peso-atual-ruim .progress-bar {background: #a94949;}
a .peso-atual-ruim .progress-bar:hover {background: #952323;}
.no-sensor .progress-bar {background:  #f2f2f2; color: grey;}
a .no-sensor .progress-bar:hover {background: #cfcfcf;}
.disable .progress-bar {background:  #adadad;}
a .disable .progress-bar:hover {background: grey;}

.feed-data-wrapper {
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.feed-data {
  flex: 1 1 calc(50% - 5px); 
  max-width: calc(50% - 5px); 
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 
}

.feed-data-big-amout {
  flex: 1 1 calc(50% - 5px); 
  max-width: calc(50% - 5px); 
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 
}

.feed-data-one {
  flex: 1 1 calc(100% - 10px); 
  max-width: calc(100% - 10px); 
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 
}

.fonteSenlote{
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-size: 12px
}

.container-information-td{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.imgIcon{
  width: 20px;
  margin: 4px;
  margin-right: 8px;

}

.feed-data {
  width: -moz-available;
  width: -webkit-fill-available;
}

.peso-feed-acima {background: #415430;}
.peso-feed-acima:hover {background: #273619;}
.peso-feed-bom {background: #a1cd7b;}
.peso-feed-bom:hover {background: #5e7d42;}
.peso-feed-medio {background: #e2bf65;}
.peso-feed-medio:hover {background: #c59e3a;}
.peso-feed-ruim {background: #c25757;}
.peso-feed-ruim:hover {background: #952323;}
.feed-disabled {background:  #adadad;}
.feed-disabled:hover {background: grey;}

.product-information {
  margin: 0px;
  padding: 0px;
  font-size: 12px
}

.product-information span{
  margin: 0px;
  margin-right: 4px;
  padding: 0px;
  font-size: 12px;
  font-weight: 700;
}


#peso-feed-bom-text{
  color: #003d03;
}

#peso-feed-medio-text {
  color:#3d3900;
}

#feed-disabled-text {
  color: white
}

#peso-feed-ruim-text{
  color:#3d0000; 
}

.card-button {
  cursor: pointer;
}

@media screen and (min-width: 991px) {
  .imgIcon{
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .product-information {
    font-size: 10px
  }
  .product-information span{
    font-size: 10px
  }
}
</style>
